/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cl from './Header.module.css';
import logo from './logo2.svg';

export default function Header() {
  const location = useLocation();

  return (
    <div
      className={
        location.pathname === '/' ? cl.HeaderMain : cl.HeaderAnotherPage
      }
    >
      <div className={cl.UpperHeaderPart}>
        {location.pathname === '/' ? (
          <Link to="/">
            <nav>
              <img className={cl.navlogo} src={logo} alt="Таня Половикова" />
            </nav>
          </Link>
        ) : (
          <Link to="/">
            <img className={cl.navlogo} src={logo} alt="Таня Половикова" />
          </Link>
        )}
      </div>
      <div className={cl.HeaderMenu}>
        <div className={cl.HeaderMenu_main}>
          {location.pathname === '/blog' ? (
            <Link to="/blog" className={cl.active}>
              <div className={cl.active}>

                БЛОГ

              </div>
            </Link>
          ) : (
            <Link to="/blog" className={cl.noactive}>
              <div className={cl.noactive}>
                <nav>
                  БЛОГ
                </nav>
              </div>
            </Link>

          )}
          {location.pathname === '/tests' ? (
            <Link to="/tests" className={cl.active}>
              <div className={cl.active}>
                ТЕСТЫ
              </div>
            </Link>
          ) : (
            <Link to="/tests" className={cl.noactive}>
              <div className={cl.noactive}>
                <nav>
                  ТЕСТЫ
                </nav>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
