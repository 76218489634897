/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import cl from './BlogCard.module.css';

export default function BlogCard(props) {
  return (
    <div className={cl.blogCard}>
      <h3 className={cl.title}>{props.title}</h3>
      <div className={cl.theme}>
        {`${`${props.brief}`.slice(0, 150)} ...`}
      </div>
      <Link to={`/blog/${props.id}`}>
        <button type="button" className={cl.blogBtn}>Прочитать</button>
      </Link>
    </div>
  );
}
