/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BlogContext } from '../../../context/Blog.context';

import cl from './cardPage.module.css';

export default function BlogCardPage() {
  const params = useParams();
  const { blogContent } = useContext(BlogContext);

  return (
    <article>
      <div className={cl.blogPage}>
        <h4 className={cl.blogTitle}>{blogContent[params.id - 1].title}</h4>
        <div className={cl.blogContent}>{blogContent[params.id - 1].content}</div>
        <div className={cl.blogSignature}>
          <p className={cl.blogSignatureP}>{blogContent[params.id - 1].author}</p>
          <p className={cl.blogSignatureP}>{blogContent[params.id - 1].date}</p>
        </div>

      </div>
      <Link to="/blog">
        <button type="button" className={cl.blogBtn2}>Назад</button>
      </Link>
    </article>
  );
}
