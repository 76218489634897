/* eslint-disable max-len */
import React from 'react';

import { Link } from 'react-router-dom';
import cl from '../Tests.module.css';

export default function YellBrownTestBlock() {
  return (
    <div className={cl.oneTestDiv}>
      <div>
        <h3>
          Тест Йеля-Брауна для выявления ОКР
        </h3>
        <div className={cl.hashes}>
          <div className={cl.hash}>10 вопросов</div>
          <div className={cl.hash}>Навязчивые мысли</div>
          <div className={cl.hash}>Навязчивые действия</div>
          <div className={cl.hash}>ОКР</div>
        </div>
      </div>
      <div className={cl.testDescription}>
        <p>
          Шкала Йеля-Брауна клиническая тестовая методика, разработанная Вейном Гудманом (Wayne Goodman) и его коллегами в Йельском
          и Брауновском университетах в 1989 году. Предназначена для количественного определения составляющих ОКР (обцессивно-компульсивного расстройства) и их динамики:
          навязчивых мыслей (обсессий)
          навязчивых действий (компульсий)
        </p>
      </div>
      <div>
        <Link to="/tests/yellbrowntest" className={cl.testBtn}>
          Пройти тест
        </Link>
      </div>
    </div>
  );
}
