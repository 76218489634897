import React from 'react';

import cls from './BlockStyles.module.css';
import master from '../img/master.jpeg';

export default function MasterBlock() {
  return (
    <div className={cls.photoBlock} style={{ backgroundImage: `url(${master})` }} />
  );
}
