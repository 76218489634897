import React from 'react';
import { Link } from 'react-router-dom';

import cl from '../Tests.module.css';

export default function KillManTestBlock() {
  return (
    <div className={cl.oneTestDiv}>
      <div>
        <h3>
          Тест Томаса Килмана для определения поведения в конфликтных ситуациях
        </h3>
        <div className={cl.hashes}>
          <div className={cl.hash}>30 вопросов</div>
          <div className={cl.hash}>Поведение в конфликтных ситуациях</div>
          <div className={cl.hash}>Конфликтность</div>
          <div className={cl.hash}>Способы поведения</div>
        </div>
      </div>
      <div className={cl.testDescription}>
        <p>
          Данный тест предназначен для выявления вашей
          типичной реакции на конфликтную ситуацию и личностной предрасположенности к
          конфликтному поведению,
          а также для выявления определенных стилей разрешения конфликтной
          ситуации.
        </p>
      </div>
      <div>
        <Link to="/tests/thomaskilmantest" className={cl.testBtn}>
          Пройти тест
        </Link>
      </div>
    </div>
  );
}
