/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { TestContext } from '../../../context/Test.context';

import cl from './Services.module.css';

import mac from './svg/mac_session.svg';
import offline from './svg/offline_consultation.svg';
import online from './svg/online_consultation.svg';
import consult from './svg/consult.svg';
import individual from './svg/individual.svg';
import family from './svg/family.svg';

import timeIcon from './svg/time2.svg';
import priceIcon from './svg/publePriceSVG.svg';

import separator2 from './separator2.svg';

export default function Services() {
  const { modal, setModal, setServiceValue } = useContext(TestContext);

  return (
    <div className={cl.wrapper}>
      <div className={cl.headerAndSeparator}>
        <h2>Как я могу вам помочь?</h2>
        <img className={cl.headerSeparator} src={separator2} alt="" />
      </div>
      <div className={cl.servicesDiv}>

        <div className={cl.servicesList}>

          {/* 1 */}

          {/* <div className={cl.service}>
            <div className={cl.serviceName}>
              <h4>Диагностическая консультация</h4>
            </div>
            <div className={cl.serviceIcon}>
              <img src={consult} alt="Консультация" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Знакомство, обсуждение Вашей проблемы, подбор методики её решения,
                плана и графика консультаций
              </p>
            </div>
            <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>Бесплатно</h5>
            </div>
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <h5>15 минут</h5>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="Диагностическая консультация"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div> */}

          <div className={cl.service}>
            <div className={cl.serviceName}>
              <h3>МАК-сессия</h3>
            </div>
            <div className={cl.serviceIcon}>
              <img src={mac} alt="МАК-сессия" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Персональная работа с вами с помощью метафорических ассоциативных
                карт. С МАК можно безопасно получить доступ к нашему подсознанию и
                найти способы разрешения деструктивных моментов в жизни
              </p>
            </div>
            {/* <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>1500 руб</h5>
            </div> */}
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <text>30 минут</text>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="МАК-сессия"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div>

          {/* 2 */}
          {/* <div className={cl.service}>
            <div className={cl.serviceName}>
              <h4>Разбор конфликтной ситуации</h4>
            </div>
            <div className={cl.serviceIcon}>
              <img src={offline} alt="rff" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Определение причин возникновения негативной ситуации и её
                разбор по структуре конфликта
              </p>
            </div>
            <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>1000 руб</h5>
            </div>
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <h5>30 минут</h5>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="Разбор конфликтной ситуации"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div> */}
          {/* 3 */}
          <div className={cl.service}>
            <div className={cl.serviceName}>
              <h3>Online консультация</h3>
            </div>
            <div className={cl.serviceIcon}>
              <img src={online} alt="rff" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Работаю в интегральном подходе, опираясь на личный опыт
                преодоления конфликтных ситуаций
              </p>

            </div>
            {/* <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>2500 руб</h5>
            </div> */}
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <text>55 минут</text>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="Online консультация"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div>
          {/* 4 */}

          {/* 5 */}
          <div className={cl.service}>
            <div className={cl.serviceName}>
              <h3>Online консультация для пар и семей</h3>
            </div>
            <div className={cl.serviceIcon}>
              <img src={family} alt="Консультация для пар и семей" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Применяю медиативную технологию. Разбор латентных и открытых конфликтов в отношениях,
                помощь в поиске стратегии поведения для каждого
              </p>
            </div>
            {/* <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>4500 руб</h5>
            </div> */}
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <text>90 минут</text>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="Консультация для пар и семей"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div>
          {/* 6 */}
          {/* <div className={cl.service}>
            <div className={cl.serviceName}>
              <h4>Работа по индивидуальному плану</h4>
            </div>
            <div className={cl.serviceIcon}>
              <img src={individual} alt="Индивидуальный план" />
            </div>

            <div className={cl.serviceDetails}>
              <p>
                Подборка индивидуальной эффективной методики и плана для проработки Вашей
                проблемы
              </p>
            </div>
            <div className={cl.servicePrice}>
              <img className={cl.priceIcon} src={priceIcon} alt="" />
              <h5>Индивидуально</h5>
            </div>
            <div className={cl.serviceTime}>
              <img className={cl.timeIcon} src={timeIcon} alt="" />
              <h5>Индивидуально</h5>
            </div>
            <div className={cl.serviceButton}>
              <button
                type="button"
                value="Индивидуальный план"
                className={cl.serviceButtonElement}
                onClick={(e) => {
                  setServiceValue(e.target.value);
                  setModal(true);
                }}
              >
                Записаться
              </button>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}
