import React from 'react';
import { Link } from 'react-router-dom';

import cl from '../Tests.module.css';

export default function BoykoTestBlock() {
  return (
    <div className={cl.oneTestDiv}>
      <div>
        <h3>
          Опросник коммуникативной толерантности по методике В.В.Бойко
        </h3>
        <div className={cl.hashes}>
          <div className={cl.hash}>45 вопросов</div>
          <div className={cl.hash}>Коммуникации</div>
          <div className={cl.hash}>Толерантность к другим</div>
          <div className={cl.hash}>Уровень конфликтности</div>
        </div>
      </div>
      <div className={cl.testDescription}>
        <p>
          Тест коммуникативной толерантности по методике В.В.Бойко
          позволяет оценить в каких аспектах отношений
          вы более всего подвержены конфликтам. А зная причину конфликта,
          вам будет легче его недопустить или найти способы его разрешения.
        </p>
      </div>
      <div>
        <Link to="/tests/boykotest" className={cl.testBtn}>
          Пройти тест
        </Link>
      </div>
    </div>
  );
}
