/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useRef } from 'react';

export const BackgroundContext = React.createContext();

export default function BackgroundContextProvider({ children }) {
  const [main, setMain] = useState();
  const ref = useRef(null);

  const value = useMemo(() => ({
    main,
    setMain,
    ref,
  }));

  return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>;
}
