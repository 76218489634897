/* eslint-disable import/no-unresolved */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Navigation } from 'swiper';
import BachelorBlock from '../EducationBlocks/BachelorBlock';
import MasterBlock from '../EducationBlocks/MasterBlock';
import ProfessionalBlock from '../EducationBlocks/ProfessionalBlock';
import NastavnikBlock from '../EducationBlocks/NastavnikBlock';

export default function EducationSwiper() {
  return (

    <Swiper navigation modules={[Navigation]} className="mySwiper2">
      <SwiperSlide>
        <div className="this-slide">
          <BachelorBlock />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="this-slide">
          <MasterBlock />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="this-slide">
          <ProfessionalBlock />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="this-slide">
          <NastavnikBlock />
        </div>
      </SwiperSlide>
    </Swiper>

  );
}
