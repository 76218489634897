import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cl from './Footer.module.css';

import telegram from './img/telegram.svg';
// import instagram from './img/instagram.svg';
import email from './img/email.svg';

import vk from './img/vk.svg';

function Footer() {
  const location = useLocation();
  return (
    <div className={cl.wrapper}>
      <div className={cl.footer}>
        <div className={cl.right_block}>
          <div className={cl.right_block_menus}>
            {location.pathname === '/' ? (
              <>
              </>
            ) : (
              <Link to="/" className={cl.footer_link}>
                <a href="/" className={cl.footer_link}>
                  Информация
                </a>
              </Link>

            )}
            <br />
            {location.pathname === '/blog' ? (
              <>
              </>
            ) : (
              <Link to="/blog" className={cl.footer_link}>
                <a href="/blog" className={cl.footer_link}>
                  Блог
                </a>
              </Link>

            )}
            <br />
            {location.pathname === '/tests' ? (
              <>
              </>
            ) : (
              <Link to="/tests" className={cl.footer_link}>
                <a href="/tests" className={cl.footer_link}>
                  Тесты
                </a>
              </Link>

            )}
          </div>
          <div className={cl.social_div}>
            <a href="https://t.me/tanyapolovikova">
              <img src={telegram} className={cl.icon} alt="telegram" title="Телеграм канал" />
            </a>
            <a href="https://m.vk.com/tanyapolovikova" title="Связаться Вконтакте">
              <img src={vk} className={cl.icon} alt="vkontakte" />
            </a>
            <a href="mailto:tanyapolovikova.psy@gmail.com" title="Связаться по E-mail">
              <img src={email} className={cl.icon} alt="email" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
