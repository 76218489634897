/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './Swiper.css';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';

// import slide_image_1 from './assets/img_1.jpg';
// import slide_image_2 from './assets/img_2.jpeg';
// import slide_image_3 from './assets/img_3.jpeg';
// import slide_image_4 from './assets/img4.jpeg';

import artem from '../reviewImg/artem.png';
import kirill from '../reviewImg/kirill.png';
import dmitriy from '../reviewImg/dmitriy.png';

import julia from '../reviewImg/julia.png';
import ivan from '../reviewImg/ivan.png';
import tyoma from '../reviewImg/tyoma.png';

// import Rate from './components/Rate';
// import ReviewPhoto from './components/ReviewPhoto';
import Review from './components/Review';

// const reviewsArr = [
//   'Что-то там',
//   'Все прошло замечательно, Таня подсказала как действовать в сложившейся ситуации, проработали внутренние страхи, которые препятствовали номальному решению вопроса',
//   'В целом все было не плохо, но могло быть отлично, а так все по прежнему хорошо. Мак сессию могу рекомендовать',
//   'Спасибо Тане, смогла разобрааться в себе в свои 86 лет. Встала крепко на ноги готоваа идти по жизни только вперед!',
//   'Спасибо большое тане! Помогла мне очень сильно. Теперь я готва ходить в школу без проблем и учить уроки уверенно!!!!! СПААСИБО',
// ];
function SwiperBlock() {
  return (
    <div className="wrapperSwiper">

      <div className="container">
        <Swiper
          effect="coverflow"
          grabCursor
          centeredSlides
          loop
          // virtualIndex={3}
          // autoplay="true"
          slidesPerView={2}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          {/* <SwiperSlide>
            <div className="my-slide">
              <ReviewPhoto photoPath={`${slide_image_1}`} myName="Юлия Васина" />
              <Review service="Online консультация" content={reviewsArr[1]} />
              <Rate stars={4} />
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div className="my-slide">
              <ReviewPhoto photoPath={`${slide_image_2}`} myName="Георгий Фабричнов" />
              <Review service="МАК сессия" content={reviewsArr[2]} />
              <Rate stars={3} />
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div className="my-slide">
              <ReviewPhoto photoPath={`${slide_image_3}`} myName="Галина Бланка" />
              <Review service="Online сессия" content={reviewsArr[3]} />
              <Rate stars={5} />
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={kirill} />
              {/* <Rate stars={5} /> */}
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="my-slide">
              <h5>
                Все хорошо
              </h5>
              <Rate stars={5} />
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={dmitriy} />
              {/* <Rate stars={5} /> */}
            </div>

          </SwiperSlide>

          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={julia} />
              {/* <Rate stars={5} /> */}
            </div>

          </SwiperSlide>

          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={ivan} />
              {/* <Rate stars={5} /> */}
            </div>

          </SwiperSlide>

          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={tyoma} />
              {/* <Rate stars={5} /> */}
            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div className="my-slide">
              <Review service="Online консультация" photo={artem} />
              {/* <Rate stars={5} /> */}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default SwiperBlock;
