import React from 'react';

import cl from './Reviews.module.css';
import SwiperBlock from './Swiper/SwiperBlock';

import separator2 from './separator2.svg';

export default function Reviews() {
  return (
    <div className={cl.wrapper}>
      <div className={cl.headerAndSeparator}>
        <h2>Отзывы о моей работе</h2>
        <img className={cl.headerSeparator} src={separator2} alt="" />
      </div>
      <SwiperBlock />
    </div>
  );
}
