import React from 'react';

import cls from './BlockStyles.module.css';
import nastavnik from '../img/nastavnik.png';

export default function NastavnikBlock() {
  return (
    <div className={cls.photoBlock} style={{ backgroundImage: `url(${nastavnik})` }} />
  );
}
