/* eslint-disable max-len */
import React from 'react';

import cls from './BlockStyles.module.css';

import bacelor from '../img/bacelor.jpeg';

export default function BachelorBlock() {
  return (
    <div className={cls.photoBlock} style={{ backgroundImage: `url(${bacelor})` }} />
  );
}
