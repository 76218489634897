/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Transition } from 'react-transition-group';
import { useInputPhoneMask } from 'use-input-phone-mask';
import { Link } from 'react-router-dom';
import { TestContext } from '../../context/Test.context';
import closeModal from './close_modal.png';

import './transitions.css';
import cl from './Modal.module.css';
import ConfidentialPolitics from '../ConfidentialPolitics/ConfidentialPolitics';

import 'react-toastify/dist/ReactToastify.css';

export default function Modal({ modal, setModal, serviceValue }) {
  const rootClasses = [cl.myModal];
  if (modal) {
    rootClasses.push(cl.active);
  }
  const [validBtn, setValidBtn] = useState(true);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const inputName = useRef(null);

  const labelName = useRef(null);
  const lablePhone = useRef(null);
  const { ref: inputRef, onChange } = useInputPhoneMask({
    mask: '+7 (###) ###-##-##',
  }); // phone mask

  const onChangeInput = (e) => {
    setUserName(e.target.value);

    if (inputName.current.value.length < 2) {
      labelName.current.id = 'invalid';
      setValidBtn(true);
    } else {
      labelName.current.id = 'valid';
      setValidBtn(false);
    }
  };

  const onInputPhone = (e) => {
    setUserPhone(e.target.value);
    const re = /^((8|\+7)[/\- ]?)?(\(?\d{3}\)?[/\- ]?)?[\d\- ]{10,10}$/;
    if (!re.test(inputRef.current.value)) {
      lablePhone.current.id = 'invalid';
      setValidBtn(true);
    } else {
      lablePhone.current.id = 'valid';
      setValidBtn(false);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();

    const userMessage = {
      userName,
      userPhone,
      text: serviceValue,
    };

    const TOKEN = '6207774565:AAGZltSLA7kvm0jJACzxOYW1tytaAvBYyms';
    const CHAT_ID = '-905036839';
    const URI = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    const text = `
    <b>На сайте выбрана услуга</b>\n <b>Имя заказчика: </b>${userMessage.userName}\n <b>Телефон: </b>${userMessage.userPhone}\n <b>Услуга: </b>${userMessage.text}\n
    `;

    axios
      .post(URI, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text,
      });
    setUserName(null);
    setUserPhone(null);
    inputName.current.value = null;
    inputRef.current.value = null;
    setModal(false);
    toast.success('Вы записаны! Я скоро свяжусь с Вами!', {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 'success',
      theme: 'light',
    });
  }

  return (
    <div className={rootClasses.join(' ')} onClick={() => setModal(false)}>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Transition in={setModal} timeout={500}>
        {(state) => (
          <div
            className={cl.myModalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={cl.close_btn}
              type="button"
              onClick={() => setModal(false)}
            >
              <img className={cl.close_img} src={closeModal} alt="" />
            </button>
            <form className={cl.loginForm} onSubmit={handleSubmit}>
              <h2>Запись на консультацию</h2>
              <h3>{serviceValue}</h3>
              <div className={cl.userBox}>
                <input
                  ref={inputName}
                  type="text"
                  id="userName"
                  name="userName"
                  required
                  maxLength={50}
                  onChange={(e) => {
                    onChangeInput(e);
                  }}
                  placeholder="Иван Иванов"
                />
                <label ref={labelName} htmlFor="userName">
                  Ваше имя
                </label>
              </div>
              <div className={cl.userBox}>
                <input
                  ref={inputRef}
                  onChange={onChange}
                  type="text"
                  id="tel"
                  name="tel"
                  required
                  maxLength={18}
                  onInput={onInputPhone}
                  placeholder="+7(___) ___-__-__"
                />
                <label htmlFor="tel" ref={lablePhone}>
                  Телефон
                </label>
              </div>
              <div className={cl.policy}>
                <label htmlFor="policyIinput" className={cl.policy_label}>
                  Я согласен(на) на
                  {' '}
                  <Link to="/confidentialpolitics">
                    <a href="/confidentialpolitics" target="_blank" rel="noreferrer">обработку персональных данных</a>
                  </Link>
                </label>
                <input id="policyIinput" type="checkbox" />
              </div>
              <button
                type="submit"
                className={cl.loginFormButton}
                disabled={validBtn}
              >
                Отправить
              </button>
            </form>
          </div>
        )}
      </Transition>
    </div>
  );
}
