/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useInputPhoneMask } from 'use-input-phone-mask';
import { ToastContainer, toast } from 'react-toastify';
import cl from './ContactForm.module.css';
import 'react-toastify/dist/ReactToastify.css';
import separator2 from './separator2.svg';

import comp from './comp.svg';

export default function ContactForm() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const labelName = useRef(null);
  const labelPhone = useRef(null);
  const inputName = useRef(null);
  // const inputPhone = useRef(null);
  const inputProblem = useRef(null);

  const [validBtn, setValidBtn] = useState(true);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');

  // const inputName = useRef(null);

  // const labelName = useRef(null);

  const { ref: inputRef, onChange } = useInputPhoneMask({
    mask: '+7 (###) ###-##-##',
  }); // phone mask

  const onChangeInput = (e) => {
    setUserName(e.target.value);

    if (inputName.current.value.length < 2) {
      labelName.current.id = 'invalid';
      setValidBtn(true);
    } else {
      labelName.current.id = 'valid';
      setValidBtn(false);
    }
  };

  const onInputPhone = (e) => {
    setUserPhone(e.target.value);
    const re = /^((8|\+7)[/\- ]?)?(\(?\d{3}\)?[/\- ]?)?[\d\- ]{10,10}$/;
    if (!re.test(inputRef.current.value)) {
      labelPhone.current.id = 'invalid';
      setValidBtn(true);
    } else {
      labelPhone.current.id = 'valid';
      setValidBtn(false);
    }
  };

  // const handleInputName = (e) => {
  //   setName(e.target.value);
  // };

  // const handleInputPhone = (e) => {
  //   setUserPhone(e.target.value);
  // };

  useEffect(() => {
    if (name) {
      labelName.current.className = `${cl.nameValue}`;
    } else {
      labelName.current.className = `${cl.nameLabel}`;
    }
    if (phone) {
      labelPhone.current.className = `${cl.phoneValue}`;
    } else {
      labelPhone.current.className = `${cl.phoneLabel}`;
    }
  }, [name, phone]);

  function handleSubmit(e) {
    e.preventDefault();

    const userMessage = {
      userName,
      userPhone,
      text: inputProblem.current?.value,
    };

    // telegram chat_bot

    const TOKEN = '6207774565:AAGZltSLA7kvm0jJACzxOYW1tytaAvBYyms';
    const CHAT_ID = '-905036839';
    const URI = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    const text = `
    <b>Заполнена форма обратной связи</b>\n <b>Имя заказчика: </b>${userMessage.userName}\n <b>Телефон: </b>${userMessage.userPhone}\n <b>Проблема: </b>${userMessage.text}\n
    `;

    axios
      .post(URI, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text,
      });

    setName(null);
    setUserPhone(null);

    inputName.current.value = null;
    inputRef.current.value = null;
    inputProblem.current.value = null;
    // showToast({ message: 'Спасибо за обращение, Я скоро свяжусь с Вами!', type: 'success' });
    toast.success('Спасибо! Я скоро свяжусь с Вами!', {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 'success',
      theme: 'light',
    });
  }
  return (
    <div className={cl.contactFormWraper}>
      <div className={cl.headerAndSeparator}>
        <h2>Готовы обсудить Вашу историю?</h2>
        <img className={cl.headerSeparator} src={separator2} alt="" />
      </div>

      <div className={cl.contactFormContent}>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className={cl.leftSide}>

          {/* </div> */}
          <div className={cl.PinFeedBack}>
            <p>
              Оставьте Ваш контакт, чтобы договориться о
              бесплатной диагностической консультации и обсудить
              Вашу ситуацию
            </p>
            {/* <hr /> */}
            {/* <img className={cl.separator} src={separator} alt="separate" /> */}
            <p>
              Во время диагностической консультации
              я выявляю и трансформирую корневые подсознательные
              причины сложившейся у Вас проблемы.
            </p>
          </div>
          <div className={cl.compDiv}>
            <img src={comp} className={cl.compImg} alt="Связаться" />
          </div>

        </div>
        <div className={cl.rightSide}>
          <div className={cl.cardForm}>
            <form className={cl.FormForm} onSubmit={handleSubmit}>
              <div className={cl.formInputGroup}>
                <div className={cl.userBox}>

                  {/* <input
                    type="text"
                    className={cl.inputName}
                    ref={inputName}
                    required
                    onChange={handleInputName}
                  />

                  <label
                    htmlFor="userName"
                    ref={labelName}
                    className={cl.nameLabel}
                  >
                    Ваше имя
                  </label> */}

                  <input
                    className={cl.inputName}
                    ref={inputName}
                    type="text"
                    id="userName"
                    name="userName"
                    required
                    maxLength={50}
                    onChange={(e) => {
                      onChangeInput(e);
                    }}
                    placeholder="Иван Иванов"
                  />
                  <label ref={labelName} htmlFor="userName" className={cl.nameLabel}>
                    Ваше имя
                  </label>

                </div>
                <div className={cl.userBox}>
                  <input
                    ref={inputRef}
                    onChange={onChange}
                    type="text"
                    // className={cl.inputPhone}
                    id="tel"
                    name="tel"
                    required
                    onInput={onInputPhone}
                    maxLength={18}
                    placeholder="+7(___) ___-__-__"
                    // onChange={handleInputPhone}
                  />
                  <label
                    htmlFor="tel"
                    // className={cl.phoneLabel}
                    ref={labelPhone}
                  >
                    Ваш телефон
                  </label>

                  {/* <input
                  ref={inputRef}
                  onChange={onChange}
                  type="text"
                  id="tel"
                  name="tel"
                  required
                  maxLength={18}
                  onInput={onInputPhone}
                  placeholder="+7(___) ___-__-__"
                />
                <label htmlFor="tel" ref={lablePhone}>
                  Телефон
                </label> */}

                  {/* <input
                    ref={inputRef}
                    className={cl.inputPhone}
                    onChange={onChange}
                    type="text"
                    id="tel"
                    name="tel"
                    required
                    maxLength={18}
                    onInput={onInputPhone}
                    placeholder="+7(___) ___-__-__"
                  /> */}
                  {/* <label htmlFor="tel" ref={lablePhone} className={cl.phoneLabel}>
                    Телефон
                  </label> */}
                </div>

                {/* Также как и в заявках */}
                {/* <div className={cl.userBox}>
                  gg
                </div>
                <div className={cl.userBox}>
                  gg2
                </div> */}

                <textarea
                  ref={inputProblem}
                  className={cl.inputText}
                  required
                  placeholder="Опишите вашу ситуацию"
                />
              </div>
              <button
                type="submit"
                className={cl.sendBtn}
              >
                Отправить заявку
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
