/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import cl from './styles/Blog.module.css';
import BlogCard from '../components/Blog/BlogCard/BlogCard';
import { BlogContext } from '../context/Blog.context';

function Blog() {
  const { blogContent } = useContext(BlogContext);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Блог психолога Тани Половиковой</title>
        <meta name="keywords" content="Психология, конфликтология, консультации психолога, онлайн консультации психолога, психолог Санкт-Петербург, психотерапия, психотерапевт, психологическое насилие, буллинг, ..." />
        <meta
          name="description"
          content="Полезные статьи психолога - конфликтолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/blog" />
      </Helmet>
      <div className={cl.blog}>
        <h1 className={cl.titleBlog}>Актуальные статьи</h1>
        {blogContent.map((el) => (
          <BlogCard id={el.id} title={el.title} brief={el.brief} content={el.content} />
        ))}

      </div>
    </>
  );
}

export default Blog;
