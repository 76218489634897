/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Chart from 'react-apexcharts';

import '../additionalStyles.css';

import cl from '../Tests.module.css';

const blockQuestions = [
  { block: 1, number: 1, question: 'Медлительные люди обычно действуют мне на нервы', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 1, number: 2, question: 'Меня раздражают суетливые, непоседливые люди', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 1, number: 3, question: 'Шумные детские игры переношу с трудом', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 1, number: 4, question: 'Оригинальные, нестандартные личности чаще всего действуют на меня отрицательно', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 1, number: 5, question: 'Безупречный во всех отношениях человек насторожил бы меня', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 2, number: 6, question: 'Меня обычно выводит из равновесия несообразительный собеседник', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 2, number: 7, question: 'Меня раздражают любители поговорить', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 2, number: 8, question: 'Я тяготился бы разговором с безразличным для меня попутчиком в поезде, самолете, если он проявит инициативу', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 2, number: 9, question: 'Я тяготился бы разговорами случайного попутчика, который уступает мне по уровню знаний и культуры', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 2, number: 10, question: 'Мне трудно найти общий язык с партнерами иного интеллектуального уровня', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 3, number: 11, question: 'Современная молодежь вызывает неприятные чувства своим внешним видом (прическа, косметика, одежда)', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 3, number: 12, question: 'Некоторые люди производят неприятное впечатление своим бескультурьем', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 3, number: 13, question: 'Представители некоторых национальностей в моем окружении откровенно не симпатичны мне', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 3, number: 14, question: 'Есть тип мужчин (женщин), который я не выношу', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 3, number: 15, question: 'Терпеть не могу деловых партнеров с низким профессиональным уровнем', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 4, number: 16, question: 'Считаю, что на грубость надо отвечать тем же', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 4, number: 17, question: 'Мне трудно скрыть, если человек мне чем-либо неприятен', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 4, number: 18, question: 'Меня раздражают люди, стремящиеся в споре настоять на своем', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 4, number: 19, question: 'Мне неприятны самоуверенные люди', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 4, number: 20, question: 'Обычно мне трудно удержаться от замечания в адрес озлобленного или нервного человека, который толкается в транспорте', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 5, number: 21, question: 'Я имею привычку поучать окружающих', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 5, number: 22, question: 'Невоспитанные люди возмущают меня', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 5, number: 23, question: 'Я часто ловлю себя на том, что пытаюсь воспитывать кого-либо', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 5, number: 24, question: 'Я по привычке постоянно делаю кому-либо замечания', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 5, number: 25, question: 'Я люблю командовать близкими', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 6, number: 26, question: 'Меня раздражают старики, когда в час пик они оказываются в городском транспорте или в магазинах', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 6, number: 27, question: 'Жить в номере гостиницы с посторонним человеком — для меня просто пытка', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 6, number: 28, question: 'Когда партнер не соглашается в чем-то с моей правильной позицией, то обычно это раздражает меня', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 6, number: 29, question: 'Я проявляю нетерпение, когда мне возражают', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 6, number: 30, question: 'Меня раздражает, если партнер делает что-то по-своему, не так, как мне того хочется', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 7, number: 31, question: 'Обычно я надеюсь, что моим обидчикам достанется по заслугам', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 7, number: 32, question: 'Меня часто упрекают в ворчливости', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 7, number: 33, question: 'Я долго помню нанесенные мне обиды теми, кого я ценю или уважаю', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 7, number: 34, question: 'Нельзя прощать сослуживцам бестактные шутки', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 7, number: 35, question: 'Если деловой партнер непреднамеренно заденет мое самолюбие, я на него, тем не менее, обижусь', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 8, number: 36, question: 'Я осуждаю людей, которые плачутся в чужую жилетку', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 8, number: 37, question: 'Внутренне я не одобряю коллег (приятелей), которые при удобном случае рассказывают о своих болезнях', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 8, number: 38, question: 'Я стараюсь уходить от разговора, когда кто-нибудь начинает жаловаться на свою семейную жизнь', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 8, number: 39, question: 'Обычно я без особого внимания выслушиваю исповеди друзей (подруг)', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 8, number: 40, question: 'Мне иногда нравится позлить кого-нибудь из родных или друзей', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 9, number: 41, question: 'Как правило, мне трудно идти на уступки партнерам', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 9, number: 42, question: 'Мне трудно ладить с людьми, у которых плохой характер', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 9, number: 43, question: 'Обычно я с трудом приспосабливаюсь к новым партнерам по совместной работе', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 9, number: 44, question: 'Я воздерживаюсь поддерживать отношения с несколько странными людьми', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 9, number: 45, question: 'Чаще всего я из принципа настаиваю на своем, даже если понимаю, что партнер прав', answer0: 0, answer1: 1, answer2: 2, answer3: 3 },
  { block: 10, number: 46, question: null, answer0: null, answer1: null, answer2: null, answer3: null },
];
export default function BoykoTest() {
  const [visibleQuestionBoyko, setVisibleQuestionBoyko] = useState(0);

  const [firstBlock, setfirstBlock] = useState(0);
  const [secondBlock, setSecondBlock] = useState(0);
  const [thirdBlock, setThirdBlock] = useState(0);
  const [fourthBlock, setFourthBlock] = useState(0);
  const [fifthBlock, setFifthBlock] = useState(0);
  const [sixthBlock, setSixthBlock] = useState(0);
  const [seventhBlock, setSeventhBlock] = useState(0);
  const [eighthBlock, setEighthBlock] = useState(0);
  const [ninthBlock, setNinthBlock] = useState(0);

  const [hiddenTestsBoyko, setHiddenTestsBoyko] = useState(false);
  const [hiddenResultsBoyko, setHiddenResultsBoyko] = useState(true);

  const total = firstBlock + secondBlock + thirdBlock + fourthBlock + fifthBlock + sixthBlock + seventhBlock + eighthBlock + ninthBlock;
  const resultsArrBoyko = [
    { count: firstBlock, title: 'Вы не умеете либо не хотите понимать или принимать индивидуальные особенности других людей' },
    { count: secondBlock, title: 'Образ мыслей или отдельные характеристики людей, вы рассматриваете в качестве эталона самого себя' },
    { count: thirdBlock, title: 'Вы категоричны или консервативны в оценках людей, вам не хватает гибкости и широты кругозора' },
    { count: fourthBlock, title: 'Вы не умеете скрывать или хотя бы сглаживать неприятные чувства, возникающие при столкновении с некоммуникабельными качествами у партнеров' },
    { count: fifthBlock, title: 'Вы стремитесь переделать, перевоспитать, изменить по вашему желанию своего собеседника' },
    { count: sixthBlock, title: 'Вам хочется подогнать других к своему характеру,  привычкам, притязаниям' },
    { count: seventhBlock, title: 'Вы не умеете прощать другому его ошибки, неловкость, непреднамеренно причиненные вам неприятности' },
    { count: eighthBlock, title: 'Вы не терпимы к физическому или психическому дискомфорту, в котором оказался другой' },
    { count: ninthBlock, title: 'Вы плохо приспосабливаетесь к характерам, привычкам или притязаниям других' }];

  function setNext(e) {
    setVisibleQuestionBoyko(visibleQuestionBoyko + 1);

    if (visibleQuestionBoyko === 44) {
      setHiddenResultsBoyko(false);
      setHiddenTestsBoyko(true);
    }
    switch (`${e.target.id}`) {
      case '1':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
        // setQuestion1(e.target.value),
      case '2':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
        // setQuestion1(e.target.value),s
      case '3':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '4':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '5':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '6':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '7':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '8':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '9':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '10':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '11':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '12':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '13':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '14':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '15':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '16':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '17':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '18':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '19':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '20':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '21':
        return (setFifthBlock(fifthBlock + Number(e.target.value)));
      case '22':
        return (setFifthBlock(fifthBlock + Number(e.target.value)));
      case '23':
        return (setFifthBlock(fifthBlock + Number(e.target.value)));
      case '24':
        return (setFifthBlock(fifthBlock + Number(e.target.value)));
      case '25':
        return (setFifthBlock(fifthBlock + Number(e.target.value)));
      case '26':
        return (setSixthBlock(sixthBlock + Number(e.target.value)));
      case '27':
        return (setSixthBlock(sixthBlock + Number(e.target.value)));
      case '28':
        return (setSixthBlock(sixthBlock + Number(e.target.value)));
      case '29':
        return (setSixthBlock(sixthBlock + Number(e.target.value)));
      case '30':
        return (setSixthBlock(sixthBlock + Number(e.target.value)));
      case '31':
        return (setSeventhBlock(seventhBlock + Number(e.target.value)));
      case '32':
        return (setSeventhBlock(seventhBlock + Number(e.target.value)));
      case '33':
        return (setSeventhBlock(seventhBlock + Number(e.target.value)));
      case '34':
        return (setSeventhBlock(seventhBlock + Number(e.target.value)));
      case '35':
        return (setSeventhBlock(seventhBlock + Number(e.target.value)));
      case '36':
        return (setEighthBlock(eighthBlock + Number(e.target.value)));
      case '37':
        return (setEighthBlock(eighthBlock + Number(e.target.value)));
      case '38':
        return (setEighthBlock(eighthBlock + Number(e.target.value)));
      case '39':
        return (setEighthBlock(eighthBlock + Number(e.target.value)));
      case '40':
        return (setEighthBlock(eighthBlock + Number(e.target.value)));
      case '41':
        return (setNinthBlock(ninthBlock + Number(e.target.value)));
      case '42':
        return (setNinthBlock(ninthBlock + Number(e.target.value)));
      case '43':
        return (setNinthBlock(ninthBlock + Number(e.target.value)));
      case '44':
        return (setNinthBlock(ninthBlock + Number(e.target.value)));
      case '45':
        return (setNinthBlock(ninthBlock + Number(e.target.value)));
      default: return '';
    }
  }
  const even = (element) => element.count > 9;
  const nodeRef = useRef(null);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Диагностика коммуниккативной толерантности В.В.Бойко</title>
        <meta name="keywords" content="тест Бойко, коммуникативная толерантность, психологические тесты, тест на агрессивность, тест на конфликтность, ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests/boykotest" />
      </Helmet>
      <div className={hiddenTestsBoyko ? (`${cl.testsDivResult}`) : (`${cl.testsDivTest}`)}>
        <h2 hidden={hiddenTestsBoyko}>Диагностика коммуниккативной толерантности В.В.Бойко</h2>
        <p hidden={hiddenTestsBoyko}>
          Проверьте себя: ниже приводятся суждения;
          <br />
          Выберите вариант, который больше Вам подходит
        </p>

        <div className={hiddenResultsBoyko ? (`${cl.testWindow}`) : (`${cl.testWindowBoyko}`)}>
          <div className={hiddenTestsBoyko ? (`${cl.testHidden}`) : (`${cl.test}`)}>
            <div className={cl.count}>
              {blockQuestions[visibleQuestionBoyko]?.number}
              /45
            </div>
            <div className={cl.testQuestionWrapper}>
              <div className={cl.testQuestion}>{blockQuestions[visibleQuestionBoyko]?.question}</div>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestions[visibleQuestionBoyko].block} id={blockQuestions[visibleQuestionBoyko].number} value={0} onClick={(e) => setNext(e)}>
                Неверно
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestions[visibleQuestionBoyko].block} id={blockQuestions[visibleQuestionBoyko].number} value={1} onClick={(e) => setNext(e)}>
                Верно в некоторой степени
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestions[visibleQuestionBoyko].block} id={blockQuestions[visibleQuestionBoyko].number} value={2} onClick={(e) => setNext(e)}>
                Верно в значительной степени
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestions[visibleQuestionBoyko].block} id={blockQuestions[visibleQuestionBoyko].number} value={3} onClick={(e) => setNext(e)}>
                Верно в высшей степени
              </button>
            </div>

          </div>

          <div className={hiddenResultsBoyko ? (`${cl.resultHidden}`) : (`${cl.result}`)}>
            <h1>Результат:</h1>
            <p>
              Общий уровень нетолерантности к окружающим:
            </p>
            <h5>
              {`${Math.round(Number((total) / 135) * 100)}`}
              %
            </h5>
            <p>{(total < 45) ? 'Высокая степень толерантности' : ((total >= 45 && total < 85) ? 'Средняя степень толерантности' : ((total >= 85 && total < 125) ? 'Низкая степень толерантности' : 'Полное неприятие окружающих'))}</p>
            <div id="chart1">
              <Chart
                type="bar"
                width="90%"
                height={260}
                series={[{
                  name: 'Уровень нетолерантности',
                  data: [
                    Math.round(Number((firstBlock / 15) * 100)),
                    Math.round(Number((secondBlock / 15) * 100)),
                    Math.round(Number((thirdBlock / 15) * 100)),
                    Math.round(Number((fourthBlock / 15) * 100)),
                    Math.round(Number((fifthBlock / 15) * 100)),
                    Math.round(Number((sixthBlock / 15) * 100)),
                    Math.round(Number((seventhBlock / 15) * 100)),
                    Math.round(Number((eighthBlock / 15) * 100)),
                    Math.round(Number((ninthBlock / 15) * 100)),
                  ],
                }]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  colors: [function ({ value, seriesIndex, w }) {
                    if (value > 0 && value <= 20) {
                      return '#4CAF50';
                    }
                    if (value > 20 && value <= 40) {
                      return '#C5D86D';
                    }
                    if (value > 40 && value <= 60) {
                      return '#F9CE1D';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 80 && value <= 100) {
                      return '#D7263D';
                    }
                    return '#D9534F';
                  },
                  ],

                  plotOptions: {
                    bar: {
                      borderRadius: 3,
                      horizontal: true,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        ranges: [{
                          from: 0,
                          to: 67,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 80,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: [
                    'Неприятие индивидуальности',
                    'Использование себя в качестве эталона при оценках других',
                    'Категоричность или консерватизм в оценках людей',
                    'Неумение сглаживать неприятные чувства',
                    'Стремление перевоспитать партнера',
                    'Стремление подогнать других участников коммуникации под себя',
                    'Неумение прощать ошибки',
                    'Нетерпимость к дискомфортным',
                    'Неумение приспосабливаться к другим',

                  ],
                  xaxis: {
                    categories: [
                      'Неприятие или непонимание индивидуальности человека',
                      'Использование себя в качестве эталона при оценках других',
                      'Категоричность или консерватизм в оценках людей',
                      'Неумение скрывать или сглаживать неприятные чувства',
                      'Стремление переделать, перевоспитать партнера по общению',
                      'Стремление подогнать других участников коммуникации под себя',
                      'Неумение прощать другому ошибки',
                      'Нетерпимость к дискомфортным',
                      'Неумение приспосабливаться к другим участникам общения',
                    ],
                    max: 100,
                  },
                  // toolbar: { enabled: false },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#fff'],
                    },
                  },
                }}
              />
            </div>
            <p hidden={!resultsArrBoyko.some(even)}>Проявления нетолерантности:</p>
            {resultsArrBoyko.sort((a, b) => b.count - a.count).map((el) => {
              if (el.count > 9) {
                return (
                  <div>
                    <h4>
                      {el.title}
                    </h4>
                  </div>
                );
              }
              return (null);
            })}
          </div>
        </div>
      </div>
    </>
  );
}
