/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutMe from '../components/Main/AboutMe/AboutMe';
import Services from '../components/Main/Services/Services';
import Cover from '../components/Main/Cover/Cover';
import Education from '../components/Main/Education/Education';
import ContactForm from '../components/Main/ContactForm/ContactForm';
import Benefits from '../components/Main/Benefits/Benefits';
import Questions from '../components/Main/Questions/Questions';
import Reviews from '../components/Main/Reviews/Reviews';

export default function Main() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Психолог Таня Половикова</title>
        <meta name="keywords" content="Психология, конфликтология, консультации психолога, онлайн консультации психолога, психолог Санкт-Петербург, психотерапия, психотерапевт, ..." />
        <meta
          name="description"
          content="Индивидуальные и семейные online конусльтации. МАК сессии, проработка внутренних конфликтов, разбор конфликтных ситуаций"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/" />
      </Helmet>

      <div className="myMain">
        <main>
          <section>
            <Cover />
          </section>
          <section>
            <AboutMe />
          </section>
          <section>
            <Benefits />
          </section>
          <section>
            <Education />
          </section>
          <section>
            <Services />
          </section>
          {/* Временно отключаем, пока нет фотграфий */}
          <section>
            <Reviews />
          </section>
          <section>
            <Questions />
          </section>
          <section>
            <ContactForm />
          </section>
        </main>
      </div>
    </>
  );
}
