/* eslint-disable max-len */
import React, { useContext } from 'react';
import Problems from '../Problems/Problems';
import cl from './AboutMe.module.css';

import myPhotoPNG from './img/profile_photo.jpeg';

import { BackgroundContext } from '../../../context/Background.context';

export default function AboutMe() {
  const { ref } = useContext(BackgroundContext);
  return (
    <div className={cl.wrapper} ref={ref}>
      <div className={cl.AboutMeDiv}>
        <div className={cl.MyDivLR}>
          <div className={cl.AboutMeLeftDiv}>

            <div className={cl.AboutMeLeftH3}>
              <text>Давайте знакомиться!</text>
            </div>
            <div className={cl.AboutMeLeftH4}>
              {/* <h2> Меня зовут Таня Половикова</h2> */}
              <h2>Сделайте шаг к лучшей версии себя вместе со мной!</h2>
            </div>
            <div className={cl.AboutMeLeftP}>
              <p>
                Меня зовут Таня Половикова, и я психолог с опытом работы над самыми разными ситуациями.
                Я убеждена, что каждый человек уникален и способен на изменения и рост.

              </p>
              <p>
                Моя миссия - помочь вам раскрыть свой внутренний потенциал и наиболее полно реализовать себя как в жизни, так и в отношениях.
              </p>

              <p>
                {/* В своей работе я опираюсь на Ваш личный опыт и представления,
                как сделать Вас счастливым! */}
                Мой подход к консультациям основан на взаимопонимании, теплоте и уважении к вашим чувствам и опыту. Я создаю безопасное пространство, где вы можете делиться своими мыслями и эмоциями, не боясь осуждения. Вместе мы будем исследовать ваши чувства, мысли и поведение, чтобы выявить корни проблемы и найти наилучший способ решения.
              </p>

            </div>
          </div>
          <div className={cl.AboutMeRightDiv}>
            <div className={cl.AboutMeDivInBottomDiv}>
              <img className={cl.myPhotoPNG} src={myPhotoPNG} alt="heart" />
            </div>
          </div>
        </div>
        <Problems />
      </div>
    </div>
  );
}
