/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Chart from 'react-apexcharts';
import TestingContextProvider from '../../../context/Test.context';

import '../additionalStyles.css';
import cl from '../Tests.module.css';

const blockQuestionsYell = [
  {
    number: 1, question: 'Общая продолжительность Ваших навязчивых мыслей (обсессий) в течение суток составляет:', answerA: 'не наблюдаются вообще', answerB: 'по совокупности меньше часа', answerC: 'по совокупности 1-3 часа в течение дня', answerD: 'по совокупности 3-8 часов в течение дня', answerE: 'по совокупности более 8 часов в течение дня',
  },
  {
    number: 2, question: 'Степень нарушения повседневной жизни вследствие наличия навязчивых мыслей:', answerA: 'совсем не нарушена', answerB: 'нарушена слабо', answerC: 'чувствуется негативное влияние, но образ жизни прежний', answerD: 'сильно нарушен повседневный образ жизни', answerE: 'образ жизни полностью нарушен',
  },
  {
    number: 3, question: 'Уровень психологического дискомфорта вследствие навязчивых мыслей:', answerA: 'не испытываю вообще', answerB: 'испытываю слабый дискомфорт', answerC: 'испытываю сильный дискомфорт, но в общем, чувствую себя хорошо', answerD: 'испытываю сильный дискомфорт и это сказывается на моем самочувствии', answerE: 'практически весь день испытываю очень сильный дискомфорт',
  },
  {
    number: 4, question: 'Сопротивление навязчивым мыслям:', answerA: 'в состоянии им сопротивляться практически всегда', answerB: 'могу оказать сопротивление большей части обсессий', answerC: 'иногда я могу оказать им хорошее сопротивление', answerD: 'чаще всего я не могу сопротивляться им', answerE: 'не в состоянии сопротивляться обсессиям',
  },
  {
    number: 5, question: 'Степень контроля над навязчивыми мыслями:', answerA: 'обсессии полностью находятся под моим контролем', answerB: 'в большинстве случаев я контролирую их', answerC: 'иногда мне удается контролировать обсессии', answerD: 'могу контролировать их незначительно', answerE: 'мои обсессии неконтролируемы',
  },
  {
    number: 6, question: 'Ваша продолжительность навязчивых действий, ритуалов (компульсий) в течении суток:', answerA: 'не наблюдаются вообще (по совокупности меньше часа)', answerB: 'по совокупности час или меньше часа', answerC: 'по совокупности 1-3 часа в течение дня', answerD: 'по совокупности 3-8 часов в течение дня', answerE: 'по совокупности более 8 часов в течение дня',
  },
  {
    number: 7, question: 'Степень нарушения повседневной жизни:', answerA: 'совсем не нарушают', answerB: 'оказывают слабое влияние', answerC: 'чувствуется негативное влияние, но образ жизни прежний', answerD: 'сильно нарушают повседневный образ жизни', answerE: 'образ жизни полностью нарушен',
  },
  {
    number: 8, question: 'Уровень психологического дискомфорта:', answerA: 'не испытываю вообще', answerB: 'испытываю слабый дискомфорт', answerC: 'испытываю сильный дискомфорт, но в общем, чувствую себя хорошо', answerD: 'испытываю сильный дискомфорт и это сказывается на моем самочувствии', answerE: 'практически весь день испытываю очень сильный дискомфорт',
  },
  {
    number: 9, question: 'Сопротивление навязчивым действиям:', answerA: 'в состоянии им сопротивляться практически всегда', answerB: 'могу оказать сопротивление большей части компульсий', answerC: 'иногда я могу оказать им хорошее сопротивление', answerD: 'чаще всего я не могу сопротивляться им', answerE: 'не в состоянии сопротивляться компульсиям',
  },
  {
    number: 10, question: 'Степень контроля над навязчивыми действиями:', answerA: 'компульсии полностью находятся под моим контролем', answerB: 'в большинстве случаев я контролирую их', answerC: 'иногда мне удается контролировать компульсии', answerD: 'могу контролировать их незначительно', answerE: 'мои компульсии неконтролируемы',
  },
  {
    number: 11, question: null, answerA: null, answerB: null, answerC: null, answerD: null, answerE: null,
  },
];

export default function YellBrownTest() {
  const [visibleQuestionYell, setVisibleQuestionYell] = useState(0);

  const [obsCount, setObsCount] = useState(0);
  const [compCount, setCompCount] = useState(0);

  const [hiddenTestsYell, setHiddenTestsYell] = useState(false);
  const [hiddenResultsYell, setHiddenResultsYell] = useState(true);

  function showResult(obs, comp) {
    const x = obs + comp;
    switch (true) {
      case (x === 0):
        return 'У Вас все хорошо!';
      case (x > 0 && x <= 7):
        return 'У Вас незначительное обсессивно-компульсивное расстройство';
      case (x >= 8 && x <= 15):
        return 'У Вас слабое обсессивно-компульсивное расстройство';
      case (x >= 16 && x <= 23):
        return 'У Вас среднее обсессивно-компульсивное расстройство';
      case (x >= 24 && x <= 31):

        return 'У Вас сильное обсессивно-компульсивное расстройство';
      case (x >= 32 && x <= 40):
        return 'У Вас чрезвычайно сильное обсессивно-компульсивное расстройство!';
      default: return '';
    }
  }

  function setNext(e) {
    setVisibleQuestionYell(visibleQuestionYell + 1);

    if (visibleQuestionYell === 9) {
      setHiddenResultsYell(false);
      setHiddenTestsYell(true);
    }
    switch (`${e.target.id}${e.target.value}`) {
      case '1A':
        return (setObsCount(obsCount + 0));
      case '1B':
        return (setObsCount(obsCount + 1));
      case '1C':
        return (setObsCount(obsCount + 2));
      case '1D':
        return (setObsCount(obsCount + 3));
      case '1E':
        return (setObsCount(obsCount + 4));
      case '2A':
        return (setObsCount(obsCount + 0));
      case '2B':
        return (setObsCount(obsCount + 1));
      case '2C':
        return (setObsCount(obsCount + 2));
      case '2D':
        return (setObsCount(obsCount + 3));
      case '2E':
        return (setObsCount(obsCount + 4));
      case '3A':
        return (setObsCount(obsCount + 0));
      case '3B':
        return (setObsCount(obsCount + 1));
      case '3C':
        return (setObsCount(obsCount + 2));
      case '3D':
        return (setObsCount(obsCount + 3));
      case '3E':
        return (setObsCount(obsCount + 4));
      case '4A':
        return (setObsCount(obsCount + 0));
      case '4B':
        return (setObsCount(obsCount + 1));
      case '4C':
        return (setObsCount(obsCount + 2));
      case '4D':
        return (setObsCount(obsCount + 3));
      case '4E':
        return (setObsCount(obsCount + 4));
      case '5A':
        return (setObsCount(obsCount + 0));
      case '5B':
        return (setObsCount(obsCount + 1));
      case '5C':
        return (setObsCount(obsCount + 2));
      case '5D':
        return (setObsCount(obsCount + 3));
      case '5E':
        return (setObsCount(obsCount + 4));
      case '6A':
        return (setCompCount(compCount + 0));
      case '6B':
        return (setCompCount(compCount + 1));
      case '6C':
        return (setCompCount(compCount + 2));
      case '6D':
        return (setCompCount(compCount + 3));
      case '6E':
        return (setCompCount(compCount + 4));
      case '7A':
        return (setCompCount(compCount + 0));
      case '7B':
        return (setCompCount(compCount + 1));
      case '7C':
        return (setCompCount(compCount + 2));
      case '7D':
        return (setCompCount(compCount + 3));
      case '7E':
        return (setCompCount(compCount + 4));
      case '8A':
        return (setCompCount(compCount + 0));
      case '8B':
        return (setCompCount(compCount + 1));
      case '8C':
        return (setCompCount(compCount + 2));
      case '8D':
        return (setCompCount(compCount + 3));
      case '8E':
        return (setCompCount(compCount + 4));
      case '9A':
        return (setCompCount(compCount + 0));
      case '9B':
        return (setCompCount(compCount + 1));
      case '9C':
        return (setCompCount(compCount + 2));
      case '9D':
        return (setCompCount(compCount + 3));
      case '9E':
        return (setCompCount(compCount + 4));
      case '10A':
        return (setCompCount(compCount + 0));
      case '10B':
        return (setCompCount(compCount + 1));
      case '10C':
        return (setCompCount(compCount + 2));
      case '10D':
        return (setCompCount(compCount + 3));
      case '10E':
        return (setCompCount(compCount + 4));
      default: return '';
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Тест Йеля-Брауна для выявления ОКР</title>
        <meta name="keywords" content="Тест Йеля-Брауна, тест на ОКР, обсессивно-компульсивное расстройство, обсессии, компульсии, навязчивые мысли, навязчивые действия, ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests/yellbrowntest" />
      </Helmet>
      <div className={hiddenTestsYell ? (`${cl.testsDivResult}`) : (`${cl.testsDivTest}`)}>
        <h2 hidden={hiddenTestsYell}>Тест Йеля-Брауна для выявления ОКР</h2>
        <p hidden={hiddenTestsYell}>
          Проверьте себя: ниже приводятся суждения;
          <br />
          Выберите один из 5-х вариантов ответов, который Вам более близок
        </p>
        <div className={hiddenResultsYell ? (`${cl.testWindow}`) : (`${cl.testWindowKilman}`)}>
          <div className={hiddenTestsYell ? (`${cl.testHidden}`) : (`${cl.test}`)}>
            <div className={cl.count}>
              {blockQuestionsYell[visibleQuestionYell]?.number}
              /10
            </div>
            <p>{blockQuestionsYell[visibleQuestionYell]?.question}</p>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsYell[visibleQuestionYell].number} value="A" onClick={(e) => setNext(e)}>
                {blockQuestionsYell[visibleQuestionYell]?.answerA}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsYell[visibleQuestionYell].number} value="B" onClick={(e) => setNext(e)}>
                {blockQuestionsYell[visibleQuestionYell]?.answerB}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsYell[visibleQuestionYell].number} value="C" onClick={(e) => setNext(e)}>
                {blockQuestionsYell[visibleQuestionYell]?.answerC}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsYell[visibleQuestionYell].number} value="D" onClick={(e) => setNext(e)}>
                {blockQuestionsYell[visibleQuestionYell]?.answerD}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsYell[visibleQuestionYell].number} value="E" onClick={(e) => setNext(e)}>
                {blockQuestionsYell[visibleQuestionYell]?.answerE}
              </button>
            </div>
          </div>
          <div className={hiddenResultsYell ? (`${cl.resultHidden}`) : (`${cl.result}`)}>
            <h1>Результат:</h1>

            <div id="chart1">
              <Chart
                type="bar"
                width="90%"
                height={260}
                series={[{
                  name: 'Уровень обсессий/компульсий',
                  data:
                  [Math.round(Number((compCount) * 5)), Math.round(Number((obsCount) * 5))],
                }]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  colors: [function ({ value, seriesIndex, w }) {
                    if (value > 0 && value <= 20) {
                      return '#4CAF50';
                    }
                    if (value > 20 && value <= 40) {
                      return '#C5D86D';
                    }
                    if (value > 40 && value <= 60) {
                      return '#F9CE1D';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 80 && value <= 100) {
                      return '#D7263D';
                    }
                    return '#D9534F';
                  },
                  ],

                  plotOptions: {
                    bar: {
                      borderRadius: 3,
                      horizontal: true,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        ranges: [{
                          from: 0,
                          to: 67,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 80,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: [
                    'Обсессии',
                    'Компульсии',

                  ],
                  xaxis: {
                    categories: [
                      'Обсессии',
                      'Компульсии',
                    ],
                    max: 100,
                  },
                  // tooltip: { enabled: true },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#fff'],
                    },
                  },
                }}
              />
            </div>
            <p>{showResult(obsCount, compCount)}</p>
          </div>
        </div>
      </div>
    </>
  );
}
