/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import Header from '../../Header/Header';
import cl from './Cover.module.css';
import { BackgroundContext } from '../../../context/Background.context';

import arrow from './arrow.svg';

export default function Cover() {
  const { ref } = useContext(BackgroundContext);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className={cl.UnderHeader}>
      <Header />
      <div className={cl.h1h2}>
        <div className={cl.coverBlock}>
          <h1 className={cl.dh3}>Психологические консультации</h1>
          <p className={cl.pOnline}>Online</p>
        </div>
        <div className={cl.coverDivBtn}>
          <img src={arrow} alt="Вниз" onClick={handleClick} />
        </div>

      </div>
    </div>
  );
}
