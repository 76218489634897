/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react';

import cl from './Questions.module.css';

import answerSVG from './img/answer.svg';
import separator2 from './separator2.svg';

const questionsObj = [
  { id: 1, title: 'В каком формате проходят консультации?', text: 'В online формате (по видеосвязи)' },
  { id: 2, title: 'Где я работаю?', text: 'Онлайн консультации доступны для Вас с любой точки нашей планеты!' },
  { id: 3, title: 'Нужно ли что-то брать с собой на консультацию?', text: 'Вы можете взять с собой вещь, которую посчитаете нужной. Например если Вы ведете дневник эмоций, можете взять его с собой' },
  { id: 4, title: 'Какие платформы вы используете для online консультаций?', text: 'Любая удобная для вас (Zoom, Discord, Skype, Telegram, VK)  и пр.' },
  { id: 5, title: 'Что такое МАК-сессия?', text: 'МАК - ассоциативная методика с применением метафорических карт: Вы вытягиваете одну или несколько карт и далее идет разбор Ваших ассоциаций' },
  { id: 6, title: 'Как проходит консультация для пар и семей?', text: 'В зависимости от ситуации и ее предполагаемого решения, может потребоваться провести с каждым отдельную консультацию, а после назначить совместную' },
  { id: 7, title: 'Сколько сессий нужно для результата?', text: 'Все зависит от Вашей проблемы и Вашего позитивного прогресса. Для кого-то достаточно одной консультации, для кого-то необходимо провести несколько для успешной проработки проблемы' },
];
export default function Questions() {
  const [question, setQuestion] = useState('1');

  return (
    <div className={cl.wrapper}>
      <div className={cl.headerAndSeparator}>
        <h2>Часто задаваемые вопросы</h2>
        <img className={cl.headerSeparator} src={separator2} alt="" />
      </div>
      <div className={cl.QuestionsDivTop} />
      <div className={cl.QuestionsDiv}>

        <div className={cl.QuestionsDivBottom}>

          <div className={cl.QuestionsDivBottomLeft}>
            <div className={cl.answerPolygon}>
              <p>{questionsObj[Number(question) - 1].text}</p>
            </div>
            <img src={answerSVG} className={cl.meSvg} alt="Ответ на ваш вопрос" />
          </div>

          <div className={cl.QuestionsDivBottomRight}>

            <div id="1" className={question === '1' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="1" className={cl.filledCheckbox}>
                <div id="1" className={question === '1' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="1">{questionsObj[0].title}</p>
            </div>

            <div id="2" className={question === '2' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="2" className={cl.filledCheckbox}>
                <div id="2" className={question === '2' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="2">{questionsObj[1].title}</p>
            </div>

            <div id="3" className={question === '3' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="3" className={cl.filledCheckbox}>
                <div id="3" className={question === '3' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="3">{questionsObj[2].title}</p>
            </div>

            <div id="4" className={question === '4' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="4" className={cl.filledCheckbox}>
                <div id="4" className={question === '4' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="4">{questionsObj[3].title}</p>
            </div>

            <div id="5" className={question === '5' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="5" className={cl.filledCheckbox}>
                <div id="5" className={question === '5' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="5">{questionsObj[4].title}</p>
            </div>
            <div id="6" className={question === '6' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="6" className={cl.filledCheckbox}>
                <div id="6" className={question === '6' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="6">{questionsObj[5].title}</p>
            </div>

            <div id="7" className={question === '7' ? cl.QuestionListElementActive : cl.QuestionListElement} onClick={(e) => setQuestion(e.target.id)}>
              <div id="7" className={cl.filledCheckbox}>
                <div id="7" className={question === '7' ? cl.chosen : cl.nonChosen}>✓</div>
              </div>
              <p id="7">{questionsObj[6].title}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
