import React from 'react';
import { Link } from 'react-router-dom';

import cl from '../Tests.module.css';

export default function SpilbergTestBlock() {
  return (
    <div className={cl.oneTestDiv}>
      <div>
        <h3>
          Тест Спилберга для определения уровня тревожности
        </h3>
        <div className={cl.hashes}>
          <div className={cl.hash}>40 вопросов</div>
          <div className={cl.hash}>Тревожность</div>
          <div className={cl.hash}>Реактивная тревожность</div>
          <div className={cl.hash}>Персональная тревожность</div>
        </div>
      </div>
      <div className={cl.testDescription}>
        <p>
          Данный тест состоит из 20 высказываний, относящихся к тревожности как
          состоянию (состояние тревожности, реактивная или ситуативная тревожность) и из 20
          высказываний на определение тревожности как диспозиции, личностной особенности
          (свойство тревожности).
        </p>
      </div>
      <div>
        <Link to="/tests/spilbergtest" className={cl.testBtn}>
          Пройти тест
        </Link>
      </div>
    </div>
  );
}
