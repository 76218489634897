/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import cls from './BlockStyles.module.css';

import prof from '../img/prof.jpeg';

export default function ProfessionalBlock() {
  return (
    <div className={cls.photoBlock} style={{ backgroundImage: `url(${prof})` }} />
  );
}
