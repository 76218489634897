/* eslint-disable max-len */
import React from 'react';

import cl from './Benefits.module.css';

export default function Benefits() {
  return (
    <div className={cl.wrapper}>

      <div className={cl.BenefitsDiv}>
        <div className={cl.BenefitsDivUpperRow}>

          <div className={cl.BenefitElementDiv}>
            <div />
            <div className={cl.BenefitH4}><h4>Удобство и гибкость</h4></div>
            <div className={cl.BenefitP}><p>Консультации в онлайн формате - современный и гибкий подход</p></div>
          </div>

          <div className={cl.BenefitElementDiv}>
            <div className={cl.BenefitImg} />
            <div className={cl.BenefitH4}>
              <h4>Конфиденциальность</h4>
            </div>
            <div className={cl.BenefitP}>
              <p>Важный принцип моей работы - конфиденциальность наших консультаций</p>
            </div>
          </div>

          <div className={cl.BenefitElementDiv}>
            <div />
            <div className={cl.BenefitH4}>
              <h4>Индивидуальный подход</h4>
            </div>
            <div className={cl.BenefitP}>
              <p>У меня нет общих программ, но есть определенные методики работы, которые позволяют гибко работать с ситуацией у каждого</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
