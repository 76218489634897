/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import Chart from 'react-apexcharts';

import '../additionalStyles.css';
import { Helmet } from 'react-helmet-async';
import cl from '../Tests.module.css';

const blockQuestionsJung = [
  { number: 1, question: 'Что вы предпочитаете?', answerA: 'Немного близких друзей', answerB: 'Большую товарищескую компанию' },
  { number: 2, question: 'Какие книги вы предпочитаете читать?', answerA: 'С занимательным сюжетом', answerB: 'С раскрытием переживаний героев' },
  { number: 3, question: 'Что вы скорее можете допустить в работе?', answerA: 'Опоздание', answerB: 'Ошибки' },
  { number: 4, question: 'Если вы совершаете дурной поступок, то:', answerA: 'Остро переживаете', answerB: 'Острых переживаний нет' },
  { number: 5, question: 'Как вы сходитесь с людьми?', answerA: 'Быстро, легко', answerB: 'Медленно, осторожно' },
  { number: 6, question: 'Считаете ли вы себя обидчивым?', answerA: 'Да', answerB: 'Нет' },
  { number: 7, question: 'Склонны ли вы хохотать, смеяться от души?', answerA: 'Да', answerB: 'Нет' },
  { number: 8, question: 'Каким вы себя считаете?', answerA: 'Молчаливым', answerB: 'Разговорчивым' },
  { number: 9, question: 'Откровенны вы или скрытны?', answerA: 'Откровенен', answerB: 'Скрытен' },
  { number: 10, question: 'Любите ли вы заниматься анализом своих переживаний?', answerA: 'Да', answerB: 'Нет' },
  { number: 11, question: 'Находясь в обществе, что вы предпочитаете?', answerA: 'Говорить', answerB: 'Слушать' },
  { number: 12, question: 'Часто ли вы переживаете недовольство собой?', answerA: 'Да', answerB: 'Нет' },
  { number: 13, question: 'Любите ли вы что-нибудь организовывать?', answerA: 'Да', answerB: 'Нет' },
  { number: 14, question: 'Хотелось бы вам вести интимный дневник?', answerA: 'Да', answerB: 'Нет' },
  { number: 15, question: 'Быстро ли вы переходите от решения к исполнению?', answerA: 'Да', answerB: 'Нет' },
  { number: 16, question: 'Легко ли вы меняете ваше настроение?', answerA: 'Да', answerB: 'Нет' },
  { number: 17, question: 'Любите ли вы убеждать других, навязывать свои взгляды?', answerA: 'Да', answerB: 'Нет' },
  { number: 18, question: 'Ваши движения:', answerA: 'Быстры', answerB: 'Замедленны' },
  { number: 19, question: 'Вы сильно беспокоитесь о возможных неприятностях:', answerA: 'Часто', answerB: 'Редко' },
  { number: 20, question: 'В затруднительных случаях', answerA: 'Спешите обратиться за помощью к другим', answerB: 'Не любите обращаться за помощью' },
  { number: 21, question: null, answerA: null, answerB: null },
];
export default function JungTest() {
  // const { test } = useSelector((store) => store.testStore);

  const [visibleQuestionJung, setVisibleQuestionJung] = useState(0);

  const [countExtravert, setCountExtravert] = useState(0);
  const [countIntrovert, secCountIntrovert] = useState(0);

  const [hiddenTestsJung, setHiddenTestsJung] = useState(false);
  const [hiddenResultsJung, setHiddenResultsJung] = useState(true);

  const resultsArrJung = [
    { count: countExtravert, title: 'Экстраверт' },
    { count: countIntrovert, title: 'Интроверт' },
  ];

  function setNext(e) {
    setVisibleQuestionJung(visibleQuestionJung + 1);

    if (visibleQuestionJung === 19) {
      setHiddenResultsJung(false);
      setHiddenTestsJung(true);
    }
    switch (`${e.target.id}${e.target.value}`) {
      case '1A':
        return (secCountIntrovert(countIntrovert + 1));
      case '1B':
        return (setCountExtravert(countExtravert + 1));
      case '2A':
        return (setCountExtravert(countExtravert + 1));
      case '2B':
        return (secCountIntrovert(countIntrovert + 1));
      case '3A':
        return (secCountIntrovert(countIntrovert + 1));
      case '3B':
        return (setCountExtravert(countExtravert + 1));
      case '4A':
        return (secCountIntrovert(countIntrovert + 1));
      case '4B':
        return (setCountExtravert(countExtravert + 1));
      case '5A':
        return (setCountExtravert(countExtravert + 1));
      case '5B':
        return (secCountIntrovert(countIntrovert + 1));
      case '6A':
        return (secCountIntrovert(countIntrovert + 1));
      case '6B':
        return (setCountExtravert(countExtravert + 1));
      case '7A':
        return (setCountExtravert(countExtravert + 1));
      case '7B':
        return (secCountIntrovert(countIntrovert + 1));
      case '8A':
        return (secCountIntrovert(countIntrovert + 1));
      case '8B':
        return (setCountExtravert(countExtravert + 1));
      case '9A':
        return (setCountExtravert(countExtravert + 1));
      case '9B':
        return (secCountIntrovert(countIntrovert + 1));
      case '10A':
        return (secCountIntrovert(countIntrovert + 1));
      case '10B':
        return (setCountExtravert(countExtravert + 1));
      case '11A':
        return (setCountExtravert(countExtravert + 1));
      case '11B':
        return (secCountIntrovert(countIntrovert + 1));
      case '12A':
        return (secCountIntrovert(countIntrovert + 1));
      case '12B':
        return (setCountExtravert(countExtravert + 1));
      case '13A':
        return (setCountExtravert(countExtravert + 1));
      case '13B':
        return (secCountIntrovert(countIntrovert + 1));
      case '14A':
        return (secCountIntrovert(countIntrovert + 1));
      case '14B':
        return (setCountExtravert(countExtravert + 1));
      case '15A':
        return (setCountExtravert(countExtravert + 1));
      case '15B':
        return (secCountIntrovert(countIntrovert + 1));
      case '16A':
        return (setCountExtravert(countExtravert + 1));
      case '16B':
        return (secCountIntrovert(countIntrovert + 1));
      case '17A':
        return (setCountExtravert(countExtravert + 1));
      case '17B':
        return (secCountIntrovert(countIntrovert + 1));
      case '18A':
        return (setCountExtravert(countExtravert + 1));
      case '18B':
        return (secCountIntrovert(countIntrovert + 1));
      case '19A':
        return (secCountIntrovert(countIntrovert + 1));
      case '19B':
        return (setCountExtravert(countExtravert + 1));
      case '20A':
        return (secCountIntrovert(countIntrovert + 1));
      case '20B':
        return (setCountExtravert(countExtravert + 1));
      default: return '';
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Тест Юнга на определение типа личности</title>
        <meta name="keywords" content="тест Юнга, тест на экстраверта, тест на интроверта, тест на тип личности, экстраверт, интроверт, амбиверт, ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests/jungtest" />
      </Helmet>
      <div className={hiddenTestsJung ? (`${cl.testsDivResult}`) : (`${cl.testsDivTest}`)}>
        <h2 hidden={hiddenTestsJung}>Тест Юнга на определение типа личности</h2>
        <p hidden={hiddenTestsJung}>
          Проверьте себя: ниже приводятся суждения;
          <br />
          Выберите один из 2-х вариантов ответов, который более точно описывает Вас
        </p>
        <div className={hiddenResultsJung ? (`${cl.testWindow}`) : (`${cl.testWindowKilman}`)}>
          <div className={hiddenTestsJung ? (`${cl.testHidden}`) : (`${cl.test}`)}>
            <div className={cl.count}>
              {blockQuestionsJung[visibleQuestionJung]?.number}
              /20
            </div>

            <div className={cl.testQuestionWrapper}>
              <div className={cl.testQuestion}>{blockQuestionsJung[visibleQuestionJung].question}</div>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsJung[visibleQuestionJung].number} value="A" onClick={(e) => setNext(e)}>
                {blockQuestionsJung[visibleQuestionJung]?.answerA}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={blockQuestionsJung[visibleQuestionJung].number} value="B" onClick={(e) => setNext(e)}>
                {blockQuestionsJung[visibleQuestionJung]?.answerB}
              </button>
            </div>
          </div>

          <div className={hiddenResultsJung ? (`${cl.resultHidden}`) : (`${cl.result}`)}>
            <h1>Результат:</h1>

            <div id="chart1">
              <Chart
                type="bar"
                width="90%"
                height={260}
                series={[{
                  name: 'Соотношение экстраверсии/интроверсии',
                  data: [
                    Math.round(Number((countExtravert) * 5)),
                    Math.round(Number((countIntrovert) * 5)),
                  ],
                }]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  colors: [function ({ value, seriesIndex, w }) {
                    if (value > 0 && value <= 20) {
                      return '#4CAF50';
                    }
                    if (value > 20 && value <= 40) {
                      return '#C5D86D';
                    }
                    if (value > 40 && value <= 60) {
                      return '#F9CE1D';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 80 && value <= 100) {
                      return '#D7263D';
                    }
                    return '#D9534F';
                  },
                  ],

                  plotOptions: {
                    bar: {
                      borderRadius: 3,
                      horizontal: true,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        ranges: [{
                          from: 0,
                          to: 67,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 80,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: [
                    'Экстраверт',
                    'Интроверт',

                  ],
                  xaxis: {
                    categories: [
                      'Экстраверт',
                      'Интроверт',
                    ],
                    max: 100,
                  },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#fff'],
                    },
                  },
                }}
              />
            </div>

            {resultsArrJung.map((el) => {
              if (el.title === 'Экстраверт' && el.count * 5 >= 80) {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Вы ярковыраженный - Экстраверт
                      {`(${Number((el.count) * 5)}%)`}
                    </h4>
                  </div>
                );
              }
              if (el.title === 'Экстраверт' && (((el.count * 5) <= 80) && ((el.count * 5) > 60))) {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Вы преимущественно - Экстраверт
                      {`(${Number((el.count) * 5)}%)`}
                    </h4>
                  </div>
                );
              }
              if (el.title === 'Экстраверт' && (((el.count * 5) <= 60) && ((el.count * 5) > 40))) {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Вы - Амбиверт
                    </h4>

                    {/* <p>{`(${Number((el.count) * 5)}%) - экстраверт, (${100 - Number((el.count) * 5)}%) - интроверт`}</p> */}
                  </div>
                );
              }
              if (el.title === 'Экстраверт' && (((el.count * 5) <= 40) && ((el.count * 5) > 20))) {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Вы преимущественно - Интроверт
                      {`(${100 - Number((el.count) * 5)}%)`}
                    </h4>
                  </div>
                );
              }
              if (el.title === 'Экстраверт' && (((el.count * 5) <= 20) && ((el.count * 5) >= 0))) {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Вы - Интроверт
                      {`(${100 - Number((el.count) * 5)}%)`}
                    </h4>
                  </div>
                );
              }
              return (null);
            })}
          </div>
        </div>
      </div>
    </>
  );
}
