/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';

export const TestContext = React.createContext();

export default function TestContextProvider({ children }) {
  const [modal, setModal] = useState(false);
  const [serviceValue, setServiceValue] = useState();
  const [educationStage, setEducationStage] = useState('left');

  const value = useMemo(() => ({
    modal,
    setModal,
    serviceValue,
    setServiceValue,
    educationStage,
    setEducationStage,
  }));

  return <TestContext.Provider value={value}>{children}</TestContext.Provider>;
}
