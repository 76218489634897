/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import Chart from 'react-apexcharts';

import '../additionalStyles.css';
import { Helmet } from 'react-helmet-async';
import cl from '../Tests.module.css';

const blockQuestionsSpilberg = [
  {
    block: 2, number: 1, question: 'Я спокоен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 2, question: 'Мне ничего не угрожает', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 3, question: 'Я нахожусь в напряжении', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 4, question: 'Я испытываю сожаление', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 5, question: 'Я чувствую себя свободно', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 6, question: 'Я расстроен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 7, question: 'Меня волнуют возможные неудачи', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 8, question: 'Я чувствую себя отдохнувшим', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 9, question: 'Я встревожен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 10, question: 'Я испытываю чувство внутреннего удовлетворения', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 11, question: 'Я уверен в себе', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 12, question: 'Я нервничаю', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 13, question: 'Я не нахожу себе места', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 14, question: 'Я взвинчен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 15, question: 'Я не чувствую скованности', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 16, question: 'Я доволен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 17, question: 'Я озабочен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 1, number: 18, question: 'Я слишком возбужден и мне не по себе', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 19, question: 'Мне радостно', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 2, number: 20, question: 'Мне приятно', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 21, question: 'Я испытываю удовольствие', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 22, question: 'Я обычно быстро устаю', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 23, question: 'Я легко могу заплакать', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 24, question: 'Я хотел бы быть таким же счастливым, как другие', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 25, question: 'Нередко я проигрываю из-за того, что недостаточно быстро принимаю решения', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 26, question: 'Обычно я чувствую себя бодрым', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 27, question: 'Я спокоен, хладнокровен и собран', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 28, question: 'Ожидаемые трудности обычно очень тревожат меня', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 29, question: 'Я слишком переживаю из-за пустяков', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 30, question: 'Я вполне счастлив', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 31, question: 'Я принимаю все слишком близко к сердцу', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 32, question: 'Мне не хватает уверенности в себе', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 33, question: 'Обычно я чувствую себя в безопасности', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 34, question: 'Я стараюсь избегать критических ситуаций и трудностей', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 35, question: 'У меня бывает хандра', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 36, question: 'Я доволен', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 37, question: 'Всякие пустяки отвлекают и волнуют меня', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 38, question: 'Я так сильно переживаю свои разочарования, что потом долго не могу о них забыть', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 4, number: 39, question: 'Я уравновешенный человек', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 3, number: 40, question: 'Меня охватывает беспокойство, когда я думаю о своих делах и заботах', answer0: 1, answer1: 2, answer2: 3, answer3: 4,
  },
  {
    block: 0, number: 41, question: null, answer0: null, answer1: null, answer2: null, answer3: null,
  },
];

export default function SpilbergTest() {
  const [visibleQuestionSpilberg, setVisibleQuestionSpilberg] = useState(0);

  const [hiddenTestsSpilberg, setHiddenTestsSpilberg] = useState(false);
  const [hiddenResultsSpilberg, setHiddenResultsSpilberg] = useState(true);

  const [firstBlock, setfirstBlock] = useState(0);
  const [secondBlock, setSecondBlock] = useState(0);
  const [thirdBlock, setThirdBlock] = useState(0);
  const [fourthBlock, setFourthBlock] = useState(0);

  function setNext(e) {
    setVisibleQuestionSpilberg(visibleQuestionSpilberg + 1);

    if (visibleQuestionSpilberg === 39) {
      setHiddenResultsSpilberg(false);
      setHiddenTestsSpilberg(true);
    }
    switch (`${e.target.id}`) {
      case '1':
        return (setSecondBlock(secondBlock + Number(e.target.value)));

      case '2':
        return (setSecondBlock(secondBlock + Number(e.target.value)));

      case '3':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '4':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '5':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '6':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '7':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '8':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '9':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '10':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '11':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '12':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '13':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '14':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '15':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '16':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '17':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '18':
        return (setfirstBlock(firstBlock + Number(e.target.value)));
      case '19':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '20':
        return (setSecondBlock(secondBlock + Number(e.target.value)));
      case '21':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '22':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '23':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '24':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '25':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '26':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '27':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '28':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '29':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '30':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '31':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '32':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '33':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '34':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '35':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '36':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '37':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '38':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      case '39':
        return (setFourthBlock(fourthBlock + Number(e.target.value)));
      case '40':
        return (setThirdBlock(thirdBlock + Number(e.target.value)));
      default: return '';
    }
  }

  const reactTrev = firstBlock - secondBlock + 50;
  const persTrev = thirdBlock - fourthBlock + 35;
  const total = reactTrev + persTrev;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Тест Спилберга для определения уровня тревожности</title>
        <meta name="keywords" content="Тест Спилберга, тест на тревожность, диагностика тревожности, повышенная тревожность, узнать уровень тревожности, ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests/spilbergtest" />
      </Helmet>
      <div className={hiddenTestsSpilberg ? (`${cl.testsDivResult}`) : (`${cl.testsDivTest}`)}>
        <h2 hidden={hiddenTestsSpilberg}>Тест Спилберга для определения уровня тревожности</h2>
        <p hidden={hiddenTestsSpilberg}>
          Проверьте себя: ниже приводятся суждения;
          <br />
          Выберите один из 4-х вариантов, который больше всего Вам подходит
        </p>
        <div className={hiddenResultsSpilberg ? (`${cl.testWindow}`) : (`${cl.testWindowBoyko}`)}>
          <div className={hiddenTestsSpilberg ? (`${cl.testHidden}`) : (`${cl.test}`)}>
            <div className={cl.count}>
              {blockQuestionsSpilberg[visibleQuestionSpilberg]?.number}
              /40
            </div>
            <div className={cl.testQuestionWrapper}>
              <div className={cl.testQuestion}>{blockQuestionsSpilberg[visibleQuestionSpilberg]?.question}</div>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestionsSpilberg[visibleQuestionSpilberg].block} id={blockQuestionsSpilberg[visibleQuestionSpilberg].number} value={1} onClick={(e) => setNext(e)}>
                Нет, это не так
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestionsSpilberg[visibleQuestionSpilberg].block} id={blockQuestionsSpilberg[visibleQuestionSpilberg].number} value={2} onClick={(e) => setNext(e)}>
                Пожалуй, так
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestionsSpilberg[visibleQuestionSpilberg].block} id={blockQuestionsSpilberg[visibleQuestionSpilberg].number} value={3} onClick={(e) => setNext(e)}>
                Верно
              </button>
            </div>

            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" data-block={blockQuestionsSpilberg[visibleQuestionSpilberg].block} id={blockQuestionsSpilberg[visibleQuestionSpilberg].number} value={4} onClick={(e) => setNext(e)}>
                Совершенно верно
              </button>
            </div>
          </div>

          <div className={hiddenResultsSpilberg ? (`${cl.resultHidden}`) : (`${cl.result}`)}>
            <h1>Результат:</h1>
            <p>
              Общий уровень вашей тревожности:
            </p>
            <h5>
              {`${Math.round(Number((total) / 135) * 100)}`}
              %
            </h5>
            {/* <p>{total}</p> */}
            <p>{((total / 2) < 30) ? 'Это низкий уровень тревожности' : (((total / 2) >= 30 && (total / 2) <= 45) ? 'У вас умеренная степень тревожности' : (((total / 2) > 45 && (total / 2) <= 160) ? 'У вас высокая степень тревожности' : 'Что-то пошло не так:'))}</p>
            <div id="chart1">
              <Chart
                type="bar"
                width="90%"
                height={260}
                series={[{
                  name: 'Уровень тревожности',
                  data: [
                    Math.round(Number(reactTrev / 80) * 100),
                    Math.round(Number(persTrev / 80) * 100),
                  // Math.round(Number(total / 160) * 100),
                  ],
                }]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  colors: [function ({ value, seriesIndex, w }) {
                    if (value > 0 && value <= 20) {
                      return '#4CAF50';
                    }
                    if (value > 20 && value <= 40) {
                      return '#C5D86D';
                    }
                    if (value > 40 && value <= 60) {
                      return '#F9CE1D';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 80 && value <= 100) {
                      return '#D7263D';
                    }
                    return '#D9534F';
                  },
                  ],

                  plotOptions: {
                    bar: {
                      borderRadius: 3,
                      horizontal: true,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        ranges: [{
                          from: 0,
                          to: 67,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 80,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: [
                    'Реактивная тревожность',
                    'Личная тревожность',

                  ],
                  xaxis: {
                    categories: [
                      'Реактивная тревожность',
                      'Личная тревожность',
                    ],
                    max: 100,
                  },
                  // tooltip: { enabled: true },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#fff'],
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
