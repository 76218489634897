/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import cl from './styles/Tests.module.css';
// import TestingContextProvider from './context/Testing.TestingContextProvider';
import BoykoTestBlock from '../components/Tests/TestBlocks/BoykoTestBlock';
import YungTestBlock from '../components/Tests/TestBlocks/YungTestBlock';
import KillManTestBlock from '../components/Tests/TestBlocks/KillManTestBlock';
import SpilbergTestBlock from '../components/Tests/TestBlocks/SpilbergTestBlock';
import YellBrownTestBlock from '../components/Tests/TestBlocks/YellBrownTestBlock';
import { Helmet } from 'react-helmet-async';

export default function Tests() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Психологические тесты</title>
        <meta name="keywords" content="тест Бойко, тест Спилберга, тест Йеля-Брауна, тест Юнга, Тест Килмана, Тест на ОКР, тест на конфликтность, тест на тревожность, тест на толерантность к окружающим ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests" />
      </Helmet>
      <div className={cl.allTests}>
        <h1 className={cl.titleTests}>Актуальные тесты</h1>
        <BoykoTestBlock />
        <YungTestBlock />
        <KillManTestBlock />
        <SpilbergTestBlock />
        <YellBrownTestBlock />
      </div>
    </>
  );
}
