/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Chart from 'react-apexcharts';
import TestingContextProvider from '../../../context/Test.context';

import '../additionalStyles.css';
import cl from '../Tests.module.css';

const questions = [
  { question: 1, answerA: 'Иногда я предоставляю право решать проблему другим', answerB: 'Я стараюсь подчеркнуть общее в наших позициях, а не обсуждать спорные моменты' },
  { question: 2, answerA: 'Я пытаюсь найти компромиссное решение', answerB: 'Я пытаюсь учесть все интересы: свои и оппонента' },
  { question: 3, answerA: 'Обычно я твердо стою на своем', answerB: 'Иногда я могу утешать других и пытаться сохранить с ними отношения' },
  { question: 4, answerA: 'Я пытаюсь найти компромиссное решение', answerB: 'Иногда я жертвую собственными интересами ради интересов противоположной стороны' },
  { question: 5, answerA: 'При выработке решения ищу помощи со стороны других', answerB: 'Я пытаюсь сделать все возможное, чтобы избежать ненужного обострения в отношениях' },
  { question: 6, answerA: 'Я пытаюсь не создавать себе репутацию неприятного человека', answerB: 'Я пытаюсь навязать другим свою позицию' },
  { question: 7, answerA: 'Я пытаюсь отложить решение вопроса, чтобы иметь время тщательно его обдумать', answerB: 'Я жертвую одними выгодами, чтобы получить взамен другие' },
  { question: 8, answerA: 'Обычно я твердо настаиваю на своем', answerB: 'Я пытаюсь сразу же открыто обсудить все интересы и спорные вопросы' },
  { question: 9, answerA: 'Я чувствую, что различия в позициях не всегда стоят того, чтобы о них беспокоиться', answerB: 'Я прилагаю некоторые усилия, чтобы повернуть дело на свой лад' },
  { question: 10, answerA: 'Я твердо настаиваю на своем.', answerB: 'Я пытаюсь найти компромиссное решение' },
  { question: 11, answerA: 'Я пытаюсь сразу же открыто обсудить все интересы и спорные вопросы', answerB: 'Иногда я могу утешать других и пытаться сохранить с ними отношения' },
  { question: 12, answerA: 'Иногда я избегаю занимать позицию, ведущую к конфронтации', answerB: 'Я готов кое в чем уступить оппоненту, если он тоже мне уступит' },
  { question: 13, answerA: 'Я предлагаю вариант «ни вам, ни нам»', answerB: 'Я настаиваю на принятии моих условий' },
  { question: 14, answerA: 'Я излагаю оппоненту мои соображения и интересуюсь его идеями', answerB: 'Я пытаюсь продемонстрировать оппоненту логичность и выгоду принятия моих условий' },
  { question: 15, answerA: 'Иногда я могу утешать других и пытаться сохранить с ними отношения', answerB: 'Я пытаюсь сделать все возможное, чтобы избежать ненужного обострения в отношениях' },
  { question: 16, answerA: 'Я стараюсь щадить чувства других', answerB: 'Я пытаюсь убедить оппонента в выгодности принятия моих условий' },
  { question: 17, answerA: 'Обычно я твердо настаиваю на своем', answerB: 'Я пытаюсь сделать все возможное, чтобы избежать ненужного обострения в отношениях' },
  { question: 18, answerA: 'Я позволяю оппоненту придерживаться своего мнения, если ему от этого лучше ', answerB: 'Я согласен кое в чем уступить оппоненту, если он тоже кое в чем мне уступит' },
  { question: 19, answerA: 'Я пытаюсь сразу же, открыто, обсудить все интересы и спорные вопросы', answerB: 'Я пытаюсь отложить принятие решения, чтобы иметь время тщательно его обдумать' },
  { question: 20, answerA: 'Я пытаюсь сразу же обсудить противоречия', answerB: 'Я пытаюсь найти справедливое сочетание из выгод и уступок для каждого из нас' },
  { question: 21, answerA: 'При подготовке к переговорам я стараюсь учитывать интересы оппонента', answerB: 'Я больше склонен к непосредственному и открытому обсуждению проблемы' },
  { question: 22, answerA: 'Я стараюсь найти позицию, находящуюся между позицией оппонента и моей', answerB: 'Я настаиваю на своих интересах' },
  { question: 23, answerA: 'Очень часто я стараюсь удовлетворить все интересы, свои и оппонента', answerB: 'Иногда я предоставляю право решать проблему другим' },
  { question: 24, answerA: 'Я стараюсь пойти навстречу оппоненту, если его условия слишком для него многозначат', answerB: 'Я пытаюсь склонить оппонента к компромиссу' },
  { question: 25, answerA: 'Я пытаюсь продемонстрировать оппоненту логичность и выгоду принятия моих условий', answerB: 'При подготовке к переговорам я пытаюсь учитывать интересы оппонента' },
  { question: 26, answerA: 'Я предлагаю вариант «ни вам, ни нам»', answerB: 'Я почти всегда пытаюсь удовлетворить все пожелания, как свои, так и оппонента' },
  { question: 27, answerA: 'Иногда я избегаю занимать позицию, ведущую к конфронтации', answerB: 'Я позволяю оппоненту придерживаться своего мнения, если ему от этого лучше' },
  { question: 28, answerA: 'Обычно я твердо стою на своем', answerB: 'При выработке решения я постоянно ищу помощи со стороны других' },
  { question: 29, answerA: 'Я предлагаю вариант «ни вам, ни нам»', answerB: 'Я чувствую, что различия в позициях не всегда стоят того, чтобы о них беспокоиться' },
  { question: 30, answerA: 'Я стараюсь щадить чувства других', answerB: 'Я всегда стараюсь найти решение проблемы совместно с оппонентом' },
  { question: 31, answerA: 'Тест окончен', answerB: 'вы молодец' },

];

export default function ThomasKilman() {
  const [visibleQuestion, setVisibleQuestion] = useState(0);

  const [confrontation, setConfrontation] = useState(0);
  const [solving, setSolving] = useState(0);
  const [compromiss, setCompromiss] = useState(0);
  const [evasion, setEvasion] = useState(0);
  const [adapt, setAdapt] = useState(0);

  const resultsArr = [
    { name: 'Конфронтация', count: confrontation },
    { name: 'Сотрудничество', count: solving },
    { name: 'Компромисс', count: compromiss },
    { name: 'Уклонение', count: evasion },
    { name: 'Приспособление', count: adapt }];

  const [hiddenTests, setHiddenTests] = useState(false);
  const [hiddenResults, setHiddenResults] = useState(true);

  function setNext(e) {
    setVisibleQuestion(visibleQuestion + 1);

    if (visibleQuestion === 29) {
      setHiddenResults(false);
      setHiddenTests(true);
    }
    switch (`${e.target.id}${e.target.value}`) {
      case '1A':
        return (setEvasion(evasion + 1));
      case '1B':
        return (setAdapt(adapt + 1));
      case '2A':
        return (setCompromiss(compromiss + 1));
      case '2B':
        return (setSolving(solving + 1));
      case '3A':
        return (setConfrontation(confrontation + 1));
      case '3B':
        return (setAdapt(adapt + 1));
      case '4A':
        return (setCompromiss(compromiss + 1));
      case '4B':
        return (setAdapt(adapt + 1));
      case '5A':
        return (setSolving(solving + 1));
      case '5B':
        return (setEvasion(evasion + 1));
      case '6A':
        return (setEvasion(evasion + 1));
      case '6B':
        return (setConfrontation(confrontation + 1));
      case '7A':
        return (setEvasion(evasion + 1));
      case '7B':
        return (setCompromiss(compromiss + 1));
      case '8A':
        return (setConfrontation(confrontation + 1));
      case '8B':
        return (setSolving(solving + 1));
      case '9A':
        return (setEvasion(evasion + 1));
      case '9B':
        return (setConfrontation(confrontation + 1));
      case '10A':
        return (setConfrontation(confrontation + 1));
      case '10B':
        return (setCompromiss(compromiss + 1));
      case '11A':
        return (setSolving(solving + 1));
      case '11B':
        return (setAdapt(adapt + 1));
      case '12A':
        return (setEvasion(evasion + 1));
      case '12B':
        return (setCompromiss(compromiss + 1));
      case '13A':
        return (setCompromiss(compromiss + 1));
      case '13B':
        return (setConfrontation(confrontation + 1));
      case '14A':
        return (setSolving(solving + 1));
      case '14B':
        return (setConfrontation(confrontation + 1));
      case '15A':
        return (setAdapt(adapt + 1));
      case '15B':
        return (setEvasion(evasion + 1));
      case '16A':
        return (setAdapt(adapt + 1));
      case '16B':
        return (setConfrontation(confrontation + 1));
      case '17A':
        return (setConfrontation(confrontation + 1));
      case '17B':
        return (setEvasion(evasion + 1));
      case '18A':
        return (setAdapt(adapt + 1));
      case '18B':
        return (setCompromiss(compromiss + 1));
      case '19A':
        return (setSolving(solving + 1));
      case '19B':
        return (setEvasion(evasion + 1));
      case '20A':
        return (setSolving(solving + 1));
      case '20B':
        return (setCompromiss(compromiss + 1));
      case '21A':
        return (setAdapt(adapt + 1));
      case '21B':
        return (setSolving(solving + 1));
      case '22A':
        return (setCompromiss(compromiss + 1));
      case '22B':
        return (setConfrontation(confrontation + 1));
      case '23A':
        return (setSolving(solving + 1));
      case '23B':
        return (setEvasion(evasion + 1));
      case '24A':
        return (setAdapt(adapt + 1));
      case '24B':
        return (setCompromiss(compromiss + 1));
      case '25A':
        return (setConfrontation(confrontation + 1));
      case '25B':
        return (setAdapt(adapt + 1));
      case '26A':
        return (setCompromiss(compromiss + 1));
      case '26B':
        return (setSolving(solving + 1));
      case '27A':
        return (setEvasion(evasion + 1));
      case '27B':
        return (setAdapt(adapt + 1));
      case '28A':
        return (setConfrontation(confrontation + 1));
      case '28B':
        return (setSolving(solving + 1));
      case '29A':
        return (setCompromiss(compromiss + 1));
      case '29B':
        return (setEvasion(evasion + 1));
      case '30A':
        return (setAdapt(adapt + 1));
      case '30B':
        return (setSolving(solving + 1));
      default: return '';
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Тест Томаса Килмана для определения поведения в конфликтных ситуациях</title>
        <meta name="keywords" content="Тест Килмана, тест на Томаса Килмана, диагностика конфликтности, тест на конфликтность, поведение в конфликтных ситуциях, узнать уровень конфликтности, предрасположенность к конфликтному поведению, ..." />
        <meta
          name="description"
          content="Психологические тесты онлайн. Сайт психолога Тани Половиковой"
        />
        <meta />
        <link rel="canonical" href="https://tanyapolovikova.ru/tests/thomaskilmantest" />
      </Helmet>

      <div className={hiddenTests ? (`${cl.testsDivResult}`) : (`${cl.testsDivTest}`)}>
        <h2 hidden={hiddenTests}>Тест Томаса Килмана для определения поведения в конфликтных ситуациях</h2>
        <p hidden={hiddenTests}>
          Проверьте себя:
          <br />
          Выберите один из 2-х вариантов ответов, который Вам более близок
        </p>
        <div className={hiddenResults ? (`${cl.testWindow}`) : (`${cl.testWindowKilman}`)}>
          <div className={hiddenTests ? (`${cl.testHidden}`) : (`${cl.test}`)}>
            <div className={cl.count}>
              {questions[visibleQuestion]?.question}
              /30
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={questions[visibleQuestion].question} value="A" onClick={(e) => setNext(e)}>
                {questions[visibleQuestion]?.answerA}
              </button>
            </div>
            <div className={cl.btnDiv}>
              <button className={cl.answerBtn} type="button" id={questions[visibleQuestion].question} value="B" onClick={(e) => setNext(e)}>
                {questions[visibleQuestion]?.answerB}
              </button>
            </div>
          </div>

          <div className={hiddenResults ? (`${cl.resultHidden}`) : (`${cl.result}`)}>
            <h1>Результат:</h1>

            {/* для компьютера */}
            <div id="chart1" className={cl.hideForMobile}>
              <Chart
                type="radialBar"
                width="100%"
                height={260}
                series={[Math.round(Number((confrontation / 12) * 100)), Math.round(Number((solving / 12) * 100)), Math.round(Number((compromiss / 12) * 100)), Math.round(Number((evasion / 12) * 100)), Math.round(Number((adapt / 12) * 100))]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  // colors: ['#121122'],

                  plotOptions: {
                    radialBar: {
                      radius: 25,
                      width: 25,
                      height: 25,
                      enableShades: false,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        range: [{
                          from: 0,
                          to: 4,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 10,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: ['Конфронтация', 'Сотрудничество', 'Компромисс', 'Уклонение', 'Приспособление'],

                  // tooltip: { enabled: true },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                }}
              />
            </div>

            {/* Для мобильных */}

            <div id="chart1" className={cl.showForMobile}>
              <Chart
                type="bar"
                width="90%"
                height={260}
                series={[{
                  name: 'Вариант адаптации',
                  data: [Math.round(Number((confrontation / 12) * 100)), Math.round(Number((solving / 12) * 100)), Math.round(Number((compromiss / 12) * 100)), Math.round(Number((evasion / 12) * 100)), Math.round(Number((adapt / 12) * 100))],
                }]}
                options={{
                  chart: {
                  //   defaultLocale: 'ru',
                  },
                  colors: [function ({ value, seriesIndex, w }) {
                    if (value > 0 && value <= 20) {
                      return '#4CAF50';
                    }
                    if (value > 20 && value <= 40) {
                      return '#C5D86D';
                    }
                    if (value > 40 && value <= 60) {
                      return '#F9CE1D';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 60 && value <= 80) {
                      return '#F46036';
                    }
                    if (value > 80 && value <= 100) {
                      return '#D7263D';
                    }
                    return '#D9534F';
                  },
                  ],

                  plotOptions: {
                    bar: {
                      borderRadius: 3,
                      horizontal: true,
                      dataLabels: {
                        name: {
                          fontSize: '22px',
                        },
                        value: {
                        // show: true,
                          fontSize: '16px',
                        },
                        total: {
                          show: false,
                          label: 'Total',
                          formatter(w) {
                            return 30;
                          },
                        },
                      },
                      colorScale: {
                        ranges: [{
                          from: 0,
                          to: 67,
                          color: '#000000',
                        },
                        {
                          from: 6,
                          to: 80,
                          color: '#005556',
                        },
                        ],
                      },

                    },
                  },
                  labels: ['Конфронтация', 'Сотрудничество', 'Компромисс', 'Уклонение', 'Приспособление'],
                  xaxis: {
                    categories: ['Конфронтация', 'Сотрудничество', 'Компромисс', 'Уклонение', 'Приспособление'],
                    max: 100,
                  },
                  // tooltip: { enabled: true },
                  legend: {
                    show: true,
                    horizontalAlign: 'left',
                    position: 'left',
                    offsetX: 0,
                    offsetY: 0,
                    formatter(seriesName, opts) {
                      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
                    },
                  },
                  // Отступ между элементами
                  stroke: {
                    width: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      colors: ['#fff'],
                    },
                  },
                }}
              />
            </div>

            <p>Тип поведения в конфликтах:</p>
            {resultsArr.sort((a, b) => b.count - a.count).slice(0, 2).map((el) => {
              if (el.name === 'Конфронтация') {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Конфронтация
                      {`(${Math.round(Number((el.count / 12) * 100))}%)`}
                    </h4>
                    <p>
                      Конфронтация — стратегия для серьезных ситуаций и жизненно важных проблем,
                      зачастую она эффективна в экстремальных ситуациях. Противоборство оправданно, если
                      цель чрезвычайно важна или, если человек обладает реальной силой и властью, уверен в
                      своей компетентности. Если власти и силы недостаточно, можно увязнуть в конфликте, а
                      то и вовсе проиграть. Кроме того, применение конфронтации для решения проблем в
                      личных отношениях чревато отчуждением

                    </p>
                  </div>
                );
              }
              if (el.name === 'Присобление') {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Приспособление
                      {`(${Math.round(Number((el.count / 12) * 100))}%)`}
                    </h4>
                    <p>
                      Приспособление естественно в ситуациях, когда затронутая проблема не так важна для
                      человека, как для его оппонента, или отношения с оппонентом представляют собой
                      самостоятельную ценность, значимее достижения цели. Это непредсказуемая по своим
                      последствиям стратегия. Если отказ от цели не стоил человеку большого труда,
                      уступчивость может положительно сказаться на его самооценке и отношениях с
                      партнером. Очень важно чувствовать, что другой заметил и оценил жертву. В противном
                      случае остается чувство досады, обиды и, следовательно, почва для эмоционального
                      конфликта.

                    </p>
                  </div>
                );
              }
              if (el.name === 'Компромисс') {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Компромисс
                      {' '}
                      {`(${Math.round(Number((el.count / 12) * 100))}%)`}
                    </h4>
                    <p>
                      Компромисс, или квазисотрудничество, или торг о взаимных уступках. Эффективен в
                      ситуациях, требующих быстрого исхода. «Дележ» потребностей бывает необходим для
                      сохранения отношений, особенно в тех случаях, когда возместить интересы сторон
                      невозможно. Компромисс редко приносит истинное удовлетворение результатом
                      конфликтного процесса. Любые варианты дележа — пополам, поровну, по-братски —
                      психологически несправедливы. И это понятно: цель полностью не достигнута,
                      некоторая часть брошена на алтарь позитивного исхода конфликта, но оценить жертву
                      некому, так как оппонент так же пострадал, как и вы сами.
                    </p>
                  </div>
                );
              }
              if (el.name === 'Уклонение') {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Уклонение
                      {' '}
                      {`(${Math.round(Number((el.count / 12) * 100))}%)`}
                    </h4>
                    <p>
                      Уклонение эффективно в ситуациях, когда партнер обладает объективно большей силой
                      и использует ее в конфликтной борьбе. В общении со сложной конфликтной личностью
                      используйте любую возможность, чтобы избежать конфликта: в этом нет ничего
                      постыдного или унизительного. Избегание приносит положительные плоды, являясь
                      временной отсрочкой в решении конфликта: пока на руках мало данных или нет
                      психологической уверенности в своей позиции. Временно уйти от проблемы, чтобы в
                      дальнейшем решить ее окончательно — это часто единственно верная стратегия.

                    </p>
                  </div>
                );
              }
              if (el.name === 'Сотрудничество') {
                return (
                  <div className={cl.introTotal}>
                    <h4>
                      Сотрудничество
                      {`(${Math.round(Number((el.count / 12) * 100))}%)`}
                    </h4>
                    <p>
                      Сотрудничество — это не столько стратегия поведения, сколько стратегия
                      взаимодействия. Она незаменима в близких, продолжительных и ценных для обоих
                      партнеров отношениях, при равенстве статусов и психологической власти. Она
                      позволяет партнерам разрешить конфликт, не отказываясь от своих реальных целей.

                    </p>
                  </div>
                );
              }
              return (null);
            })}
          </div>
        </div>
      </div>
    </>
  );
}
