/* eslint-disable react/prop-types */
import React from 'react';

import cl from './SwiperComponentsStyle.module.css';

export default function Review({ service, content, photo }) {
  return (
    <div className={cl.reviewWrapper}>
      <h2>{service}</h2>
      {content ? (
        <p>{content}</p>
      ) : (
        <img src={photo} alt="Отзыв клиента" />
      )}

    </div>
  );
}
