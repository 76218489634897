/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import cl from './Problems.module.css';

export default function Problems() {
  return (
    <div className={cl.problemsDiv}>
      <div className={cl.problemsHeader}>
        <h2 className={cl.problemsHeaderH2}>Проблемы с которыми я работаю:</h2>
      </div>

      <div className={cl.problemsList}>
        <div className={cl.problemsLR}>
          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Тревога</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Неуверенность в себе</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Проблемы детско-родительских отношений</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Межличностные конфликты</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Внутриличностные конфликты</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Булимия и Переедание</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Трудности взаимоотношений с близкими</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Трудности в построении личных границ</h3>
            </div>
          </div>

          <div className={cl.problem}>
            <div className={cl.problemH3}>
              <h3>Проблемы с самоконтролем</h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
