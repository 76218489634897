/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import TestContextProvider from './context/Test.context';
import BackgroundContextProvider from './context/Background.context';
import BlogContextProvider from './context/Blog.context';

// import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Provider store={store}>

  <BackgroundContextProvider>
    <BlogContextProvider>
      <TestContextProvider>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </TestContextProvider>
    </BlogContextProvider>
  </BackgroundContextProvider>,
  {/* </Provider>, */},
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
