/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import {
  Route, Router, Routes, useLocation, useParams,
} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import './App.css';
import { useContext, useEffect } from 'react';

import { Transition } from 'react-transition-group';

import Main from './pages/Main';
import Tests from './pages/Tests';
import Blog from './pages/Blog';

import BoykoTest from './components/Tests/TestList/BoykoTest';
import ThomasKilman from './components/Tests/TestList/ThomasKilman';
import JungTest from './components/Tests/TestList/JungTest';
import Modal from './components/Modal/Modal';
import { TestContext } from './context/Test.context';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AboutMe from './pages/AboutMe';
import ConfidentialPolitics from './components/ConfidentialPolitics/ConfidentialPolitics';
import BlogCardPage from './components/Blog/BlogCardPage/BlogCardPage';
import SpilbergTest from './components/Tests/TestList/SpilbergTest';
import YellBrownTest from './components/Tests/TestList/YellBrownTest';

function App() {
  const { modal, setModal, serviceValue } = useContext(TestContext);

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      {location.pathname === '/' ? <> </> : <Header />}
      {/* <Router> */}
      <Routes>
        <Route index path="/" element={<Main />} />
        {/* <Route path="aboutme" element={<AboutMe />} /> */}
        <Route index path="/tests" element={<Tests />} />
        <Route index path="/tests/thomaskilmantest" element={<ThomasKilman />} />
        <Route index path="/tests/boykotest" element={<BoykoTest />} />
        <Route index path="/tests/jungtest" element={<JungTest />} />
        <Route index path="/tests/spilbergtest" element={<SpilbergTest />} />
        <Route index path="/tests/yellbrowntest" element={<YellBrownTest />} />
        <Route index path="/blog" element={<Blog />} />
        <Route index path="/blog/:id" element={<BlogCardPage />} />
        <Route index path="/confidentialpolitics" element={<ConfidentialPolitics />} />
      </Routes>
      {/* </Router> */}
      <Modal modal={modal} setModal={setModal} serviceValue={serviceValue} />
      <Footer />
    </div>
  );
}

export default App;
