import React from 'react';
import { Link } from 'react-router-dom';

import cl from '../Tests.module.css';

export default function YungTestBlock() {
  return (
    <div className={cl.oneTestDiv}>
      <div>
        <h3>
          Тест Юнга на определение типа личности
        </h3>
        <div className={cl.hashes}>
          <div className={cl.hash}>20 вопросов</div>
          <div className={cl.hash}>Интроверт</div>
          <div className={cl.hash}>Экстраверт</div>
          <div className={cl.hash}>Амбиверт</div>
        </div>
      </div>
      <div className={cl.testDescription}>
        <p>
          Типология Юнга разделяет всех людей
          по типу экстраверсии и интроверсии,
          а также промежуточному типу людей – амбивертам.
        </p>
      </div>
      <div>
        <Link to="/tests/jungtest" className={cl.testBtn}>
          Пройти тест
        </Link>
      </div>
    </div>
  );
}
