/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import cl from './Education.module.css';

// SVG

import separator2 from './separator2.svg';

import EducationSwiper from './EducationSwiper/EducationSwiper';

export default function Education() {
  return (
    <div className={cl.wrapper}>
      <div className={cl.educationDiv}>
        <div className={cl.headerAndSeparator}>
          <h2>Моя квалификация</h2>
          <img className={cl.headerSeparator} src={separator2} alt="" />

        </div>
        <div className={cl.educationBrief}>
          <p>
            Являюсь победителем и номинантом международных конкурсов
            в области конфликтологии, психологии.
          </p>
          <p>
            Интересуюсь медиацией,
            разрешением конфликтных ситуаций с помощью третьей стороны,
            уделяю внимание проблеме психологического насилия в современном обществе.
          </p>
          <p>Стремлюсь найти эффективный подход в работе с людьми.</p>
        </div>

        <div className={cl.educationLR}>

          <div className={cl.educationLeft}>
            <ul>
              <li>
                <p>
                  В 2020 году защитила бакалаврскую дипломную работу по теме: &quot;Проблема психологического
                  насилия в современном обществе&quot;.
                </p>
              </li>
              <li>
                <p>
                  в 2021 году прошла профессиональную переподготовку по направлению:
                  &quot;Семейное и детское психологическое консультирование&quot;
                  &quot;Системная семейная психотерапия&quot;.
                </p>
              </li>
              <li>
                <p>
                  в 2022 году защитила
                  магистерскую диссертацию на тему:
                  &quot; Школьная медиация в России и за рубежом: сравнительный анализ &quot;.
                </p>
              </li>
              <li>
                <p>
                  в 2023 году прошла повышение квалификации
                  по программе:
                  &quot; Наставничество как вид лидерства &quot;.
                </p>
              </li>
            </ul>

          </div>
          <div className={cl.threeDiploma}>
            <EducationSwiper />
          </div>
        </div>

      </div>
    </div>
  );
}
